import { Stack } from '@mui/material';
import IntestazioneGrafici from './IntestazioneGrafici';
import { BarChart } from '@mui/x-charts';
import { useEffect, useState } from 'react';

export default function GraficoBarre({ titolo, sottotitolo, value }) {
  const [chartData, setChartData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);

  useEffect(() => {
    if (value) {
      setXAxisData(formatAxisData(value));
      setChartData(formatChartData(value));
    } else {
      setXAxisData([]);
      setChartData([]);
    }
  }, [value]);

  const formatChartData = data => {
    return Object.values(data).map(item => item.counter);
  };

  const formatAxisData = data => {
    return Object.values(data).map(item => item.nome);
  };

  return (
    <Stack sx={{ flex: 1 }}>
      <IntestazioneGrafici titolo={titolo} sottotitolo={sottotitolo} />
      {chartData?.length > 0 && xAxisData?.length > 0 && (
        <BarChart
          xAxis={[
            {
              scaleType: 'band',
              data: xAxisData,
              categoryGapRatio: 0.9,
              colorMap: {
                type: 'piecewise',
                thresholds: [new Date(2021, 1, 1), new Date(2023, 1, 1)],
                colors: ['#3476D5', '#493FD7'],
              },
            },
          ]}
          series={[
            {
              data: chartData,
            },
          ]}
          height={300}
        />
      )}
    </Stack>
  );
}
