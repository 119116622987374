import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import { useEffect, useState } from 'react';

export default function GraficoBarreFiltrato({ data, filteredList }) {
  const [originalData, setOriginalData] = useState([]);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [xAxisData, setXAxisData] = useState([]);

  useEffect(() => {
    if (data) {
      setOriginalData(data);
    } else {
      setOriginalData([]);
    }
  }, [data]);

  useEffect(() => {
    if (filteredList) {
      let filtri = [
        {
          id: 'AA',
          nome: 'Tutte le Operazioni',
          checked: true,
        },
      ];
      let tmpFiltri = formatFiltersData(filteredList);
      filtri = [...filtri, ...tmpFiltri];
      setAvailableFilters(filtri);
    } else {
      setAvailableFilters([]);
    }
  }, [filteredList]);

  useEffect(() => {
    if (availableFilters?.length > 0) {
      if (availableFilters[0].checked) {
        setXAxisData(formatAxisData(originalData));
        setChartData(formatChartData(originalData));
      } else {
        let tmpAxisData = [];
        let tmpChartData = [];
        let filtriAttivi = availableFilters.filter(item => {
          if (item.checked) {
            return item;
          }
        });

        if (filtriAttivi.length > 0) {
          Object.values(originalData)
            .map(orData => orData)
            .map(item => {
              let tmpCounter = 0;
              filtriAttivi.forEach(filtro => {
                if (item.filteredCounter[filtro.id]) {
                  tmpCounter += item.filteredCounter[filtro.id].counter;
                }
              });

              if (tmpCounter) {
                tmpAxisData = [...tmpAxisData, item.nome];
                tmpChartData = [...tmpChartData, tmpCounter];
              }
            });
        }

        setXAxisData(tmpAxisData);
        setChartData(tmpChartData);
      }
    }
  }, [availableFilters]);

  const handleChangeCheckbox = (index, event) => {
    let tmpFiltriAttivi = [];
    if (index > 0) {
      tmpFiltriAttivi = [...availableFilters];
      tmpFiltriAttivi[index].checked = event.target.checked;
      if (!event.target.checked) {
        tmpFiltriAttivi[0].checked = false;
      }
    } else {
      tmpFiltriAttivi = availableFilters.map(item => ({
        ...item,
        checked: event.target.checked,
      }));
    }
    setAvailableFilters(tmpFiltriAttivi);
  };

  const formatFiltersData = data => {
    return Object.values(data).map(item => ({
      ...item,
      checked: true,
    }));
  };

  const formatChartData = data => {
    return Object.values(data).map(item => item.counter);
  };

  const formatAxisData = data => {
    return Object.values(data).map(item => item.nome);
  };

  return (
    <Stack flexDirection="row" sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
      <Stack sx={{ flex: 1 }}>
        {availableFilters?.length > 0 && (
          <Stack>
            {availableFilters.map((item, _index) => {
              return (
                <FormControlLabel
                  key={_index}
                  label={item.nome}
                  control={
                    <Checkbox
                      checked={availableFilters[_index].checked}
                      onChange={event => handleChangeCheckbox(_index, event)}
                      sx={{
                        color: 'blue',
                        // '&.MuiButtonBase-root': { color: 'transparent' },
                      }}
                    />
                  }
                />
              );
            })}
          </Stack>
        )}
      </Stack>
      <Stack sx={{ flex: 1 }}>
        {chartData?.length > 0 && xAxisData?.length > 0 && (
          <BarChart
            xAxis={[
              {
                scaleType: 'band',
                data: xAxisData,
                categoryGapRatio: 0.9,
                colorMap: {
                  type: 'piecewise',
                  thresholds: [new Date(2021, 1, 1), new Date(2023, 1, 1)],
                  colors: ['#3476D5', '#493FD7'],
                },
              },
            ]}
            series={[
              {
                data: chartData,
              },
            ]}
            height={300}
          />
        )}
      </Stack>
    </Stack>
  );
}
