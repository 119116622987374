import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from '../components/AppNavbar';
import Header from '../components/Header';
import SideMenu from '../components/SideMenu';
import { Outlet } from 'react-router-dom';
import { AppBar, useMediaQuery, useTheme } from '@mui/material';
import NotificationDrawer from './NotificationDrawer';
import { useEffect, useRef } from 'react';
import ComunicazioniDrawer from './ComunicazioniDrawer';
import useAuth from '../hooks/useAuth';
import pusher from '../config/pusherConfig';
import { getNotifiche } from '../redux/notifiche/operations';
import { useDispatch } from 'react-redux';

export const Layout = () => {
  const body = document.querySelector('body');
  const headerRef = useRef(null);
  const { user } = useAuth();
  const dispatch = useDispatch();
  const theme = useTheme();
  body.style.backgroundColor = theme.palette.background.paper;
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const sideMenuRef = useRef(null);
  const mainContentRef = useRef(null);

  useEffect(() => {
    // socket connection
    const checkSocketPusher = async () => {
      try {
        let c = pusher.subscribe('RENTAL_' + user.id);
        c.bind('nuovaNotifica', async () => {
          console.log('get notifiche pusher');
          dispatch(getNotifiche());
        });
      } catch (error) {
        console.log(error);
      }
    };
    checkSocketPusher();
  }, [location]);

  const calcWidth = () => {
    if (sideMenuRef.current && mainContentRef.current) {
      mainContentRef.current.style.width = `calc(100vw - ${sideMenuRef.current.offsetWidth}px - 16px)`;
    }
  };

  useEffect(() => {
    calcWidth();
    window.addEventListener('resize', calcWidth);
    return () => {
      window.removeEventListener('resize', calcWidth);
    };
  }, [sideMenuRef.current, mainContentRef.current]);

  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: 'background.paper',
        width: '100vw',
      }}
    >
      <Box ref={sideMenuRef}>
        <SideMenu />
      </Box>

      <AppNavbar />
      {/* Main content */}
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Stack spacing={0} sx={{ backgroundColor: 'background.paper' }}>
          <AppBar
            ref={headerRef}
            position="sticky"
            sx={{
              display: { xs: 'none', md: 'block' },
              bgcolor: 'background.default',
              boxShadow: 1,
              height: 58,
              pt: 1,
            }}
          >
            <Header />
          </AppBar>
          <Box
            ref={mainContentRef}
            sx={{
              borderRadius: '4px',
              bgcolor: 'background.default',
              m: 1,
              mt: { xs: '70px', md: 1 },
            }}
          >
            <Outlet />
            <NotificationDrawer headerRef={headerRef} />
            <ComunicazioniDrawer headerRef={headerRef} />
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
