import { Box, Card, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getListaRichiesteModifica } from '../../redux/noleggi/operations';
import RichiesteModificaTable from './components/RichiesteModificaTable';

export default function RichiesteModifica() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { richiesteModifica, getRichiesteModificaAction } = useSelector(
    state => state.noleggi
  );

  const richiesteConfermate = richiesteModifica.filter(
    r => r.id_stato === 'FD'
  );

  const onOpenRichiesta = async richiesta => {
    if (richiesta.id_stato === 'DC') {
      navigate('/noleggi/noleggio/edit', {
        state: {
          id: richiesta.id_richiesta,
          id_richiesta_modifica: richiesta.id,
        },
      });
    } else {
      navigate('/richieste-modifica/edit', {
        state: { id: richiesta.id },
      });
    }
  };

  const onPageLoad = () => {
    dispatch(getListaRichiesteModifica());
  };

  useEffect(() => {
    onPageLoad();
  }, []);

  return (
    <Box sx={{ p: 3 }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ mb: 2, justifyContent: 'space-between' }}
      >
        <Box>
          <Typography component="h2" variant="h4">
            Richieste di Modifica
          </Typography>
        </Box>
      </Stack>

      <Card sx={{ backgroundColor: 'background.primaryLight' }}>
        <Typography variant="body1" color="primary" sx={{ fontWeight: 'bold' }}>
          Richieste di Modifica Approvate
        </Typography>
        <Typography variant="body1">
          Accetta definitivamente la modifica e ricevi i documenti da firmare
          per ufficializzare le modifiche.
        </Typography>

        <Box sx={{ mt: 2 }}>
          <RichiesteModificaTable
            rows={richiesteConfermate}
            onOpenRichiesta={onOpenRichiesta}
            action={getRichiesteModificaAction}
          />
        </Box>
      </Card>

      <Card
        sx={{ backgroundColor: 'background.default', border: 'none', mt: 4 }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Tutte le Richieste di Modifica
        </Typography>
        <Typography variant="body1">
          Visualizza tutte le richieste di modifiche.
        </Typography>

        <Box sx={{ mt: 2 }}>
          <RichiesteModificaTable
            rows={richiesteModifica}
            onOpenRichiesta={onOpenRichiesta}
            action={getRichiesteModificaAction}
          />
        </Box>
      </Card>
    </Box>
  );
}
