import { Card, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { calcolaRata } from '../../../redux/noleggi/api';
import { useDispatch } from 'react-redux';
import { showSnackbar } from '../../../redux/app/slice';
import { formatCurrency } from '../../../utils/formatNumber';

const ImportoCanoneHome = ({
  importo,
  mesi,
  assicurazione,
  backgroundColor = 'white',
  compactMode = false,
}) => {
  const [canone, setCanone] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const calcola = async () => {
    if (!importo || !mesi || importo == 0 || mesi == 0) return;

    setIsLoading(true);
    try {
      const result = await calcolaRata({
        importo: importo,
        durata_mesi: mesi,
        flg_assicurazione: assicurazione ? 1 : 0,
      });
      setCanone(result);
    } catch (error) {
      console.error(error);
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    calcola();
  }, [importo, mesi, assicurazione]);

  const boxStyle =
    backgroundColor == 'transparent'
      ? { backgroundColor: 'transparent', p: 0, border: 'none' }
      : { backgroundColor: 'background.default', p: 2 };

  return (
    <>
      {importo == null || importo == 0 || canone?.rataMensile == null ? (
        <>
          {isLoading ? (
            <Stack
              justifyContent={'center'}
              alignItems={'center'}
              sx={{ my: 4 }}
            >
              <CircularProgress
                size={30}
                sx={{ position: 'absolute', alignSelf: 'center' }}
              />
            </Stack>
          ) : (
            <Typography variant="body1" sx={{ mb: 2, textAlign: 'center' }}>
              Inserire l'importo per calcolare la rata
            </Typography>
          )}
        </>
      ) : (
        <Card
          sx={{
            ...boxStyle,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {isLoading && (
            <CircularProgress size={30} sx={{ position: 'absolute' }} />
          )}
          <Stack
            sx={{
              visibility: isLoading ? 'hidden' : 'visible',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {!compactMode && (
              <Stack flexDirection="row" alignItems="center">
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  Canone Rata
                </Typography>
                <Typography>&nbsp; (mensile):</Typography>
              </Stack>
            )}

            <Stack flexDirection="row" alignItems="center" sx={{ mt: 2 }}>
              <Typography
                variant="h5"
                component="span"
                sx={{ color: 'primary.main', fontWeight: 'bold' }}
              >
                {formatCurrency(canone?.rataMensile)}
              </Typography>
              <Typography
                variant="body1"
                component="span"
                sx={{ color: 'primary.main' }}
              >
                /mese
              </Typography>
            </Stack>
            <Typography
              variant="body1"
              component="span"
              sx={{ color: 'primary.main' }}
            >
              per {canone?.n_mesi} Mesi (senza IVA)
            </Typography>
            <Typography variant="caption" sx={{ color: 'primary.main', mt: 2 }}>
              {formatCurrency(canone?.rataMensileIva)}/mese per {canone?.n_mesi}{' '}
              Mesi (IVA inclusa)
            </Typography>
          </Stack>
        </Card>
      )}
    </>
  );
};

export default ImportoCanoneHome;
