import {
  Box,
  Card,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import { Description } from '@mui/icons-material';
import { formatStringDate } from '../../../utils/formadDate';

export default function DocumentiGrid({ data, onDownload }) {
  const theme = useTheme();
  const matchSm = useMediaQuery(theme.breakpoints.up('sm'));

  const borderCondition = index => {
    if (matchSm) {
      return index % 3 !== 2 && index < data.length - 1;
    } else {
      return index % 2 !== 2 && index < data.length - 1;
    }
  };

  return data.length > 0 ? (
    <Grid container columns={12}>
      {data.map((doc, index) => (
        <Grid
          size={{ xs: 6, sm: 4 }}
          key={index}
          sx={{
            p: 0,
            m: 0,
          }}
        >
          <Card
            sx={{
              backgroundColor: 'background.default',
              border: 'none',
              borderRadius: 0,
              borderRight: borderCondition(index)
                ? '1px solid #e0e0e0'
                : 'none',
              px: 2,
              py: 0,
              mb: 2,
              height: '80%',
              width: '100%',
            }}
          >
            <Stack
              direction="row"
              justifyContent={'start'}
              alignItems="center"
              spacing={2}
              sx={{ cursor: 'pointer', flexGrow: 1 }}
              onClick={() => onDownload(doc.nome, doc.id)}
            >
              <Description color="primary" />
              <Box>
                <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                  {doc.nome}
                </Typography>
                <Typography variant="caption">
                  {formatStringDate(doc.data)}
                </Typography>
              </Box>
            </Stack>
          </Card>
        </Grid>
      ))}
    </Grid>
  ) : (
    <Box>
      <Typography variant="caption" color="textSecondary">
        Nessun documento presente
      </Typography>
    </Box>
  );
}
