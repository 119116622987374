import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid2 as Grid,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ArrowBackIos } from '@mui/icons-material';
import DocumentiGrid from '../../Noleggi/components/DocumentiGrid';
import { useState } from 'react';
import { downloadDocumento } from '../../../redux/noleggi/api';
import LoadingMask from '../../../components/LoadingMask';
import { DataGrid } from '@mui/x-data-grid';
import columnsData from '../../Noleggi/data/noleggi_columns';
import { showSnackbar } from '../../../redux/app/slice';
import { useDispatch } from 'react-redux';
import HighlightText from '../../../components/HighlightText';
import { downloadBlob } from '../../../utils/download';
import { formatCurrency } from '../../../utils/formatNumber';

export default function ClienteCard({ clienteData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const onDownloadDocumento = async (name, id) => {
    setIsLoading(true);
    try {
      const pdfData = await downloadDocumento(id);
      downloadBlob(pdfData, 'application/pdf', name + '.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onNoleggioEdit = id => {
    navigate('/noleggi/noleggio/edit', { state: { id: id } });
  };

  const noleggiColumns = columnsData({
    onEdit: id => onNoleggioEdit(id),
  }).filter(c => c.field !== 'cliente');

  const totaleImporto = clienteData?.noleggi.reduce((acc, noleggio) => {
    return acc + noleggio.importo;
  }, 0);
  const noleggiImportoProgressBar =
    clienteData?.noleggi.map(noleggio => {
      return {
        codice: noleggio.codice,
        importo: noleggio.importo,
        percentuale: (noleggio.importo / totaleImporto) * 100,
      };
    }) ?? [];

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {isLoading && <LoadingMask />}
      <Stack
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          backgroundColor: 'background.primaryLight',
          p: 2,
          borderRadius: '4px',
        }}
      >
        <Stack flexDirection="row" alignItems="center">
          <IconButton
            sx={{ border: 'none' }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography component="h4" variant="body">
              Clienti
            </Typography>
            <Stack flexDirection="row">
              <Typography component="h3" variant="body">
                Azienda {clienteData?.ragione_sociale}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <Grid container spacing={1}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Card sx={{ backgroundColor: 'background.default' }}>
            <Box sx={{ mb: 4 }}>
              <Typography component="h4" variant="body" color="primary">
                Dati Cliente
              </Typography>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    Dati Azienda
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Stack flexDirection="row">
                    <Stack>
                      <Typography>Ragione Sociale:</Typography>
                      <Typography>Codice Fiscale:</Typography>
                      <Typography>P.IVA:</Typography>
                    </Stack>
                    <Stack sx={{ ml: 2 }}>
                      <Typography>{clienteData.ragione_sociale}</Typography>
                      <Typography>{clienteData.cf}</Typography>
                      <Typography>{clienteData.piva}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    Contatti
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Stack flexDirection="row">
                    <Stack>
                      <Typography>Email:</Typography>
                      <Typography>Telefono:</Typography>
                    </Stack>
                    <Stack sx={{ ml: 2 }}>
                      <Typography>{clienteData.mail}</Typography>
                      <Typography>{clienteData.telefono}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card sx={{ p: 2, mt: 1, backgroundColor: 'background.default' }}>
            <Typography component="h4" variant="body">
              Documenti
            </Typography>
            <CardContent sx={{ mt: 2 }}>
              <DocumentiGrid
                data={clienteData?.documenti ?? []}
                onDownload={onDownloadDocumento}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Card sx={{ backgroundColor: 'background.default' }}>
            <Box sx={{ mb: 4 }}>
              <Typography component="h4" variant="body">
                Operazioni
              </Typography>
              <Grid container spacing={2} columns={12} sx={{ mt: 2 }}>
                <Grid size={{ md: 12, lg: 12 }} sx={{ width: '100%' }}>
                  <DataGrid
                    onRowDoubleClick={params => onNoleggioEdit(params.row.id)}
                    autoHeight
                    rows={clienteData?.noleggi ?? []}
                    columns={noleggiColumns}
                    getRowClassName={params =>
                      params.indexRelativeToCurrentPage % 2 === 0
                        ? 'even'
                        : 'odd'
                    }
                    initialState={{
                      pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    pageSizeOptions={[10]}
                    disableColumnFilter
                    disableColumnMenu
                    disableColumnSorting
                    disableColumnResize
                    density="compact"
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
          <Card sx={{ backgroundColor: 'background.default', mt: 1 }}>
            <Box>
              <Stack flexDirection="row" alignItems={'center'}>
                <Typography component="h4" variant="body">
                  Totale Importo Azienda:
                </Typography>
                <HighlightText
                  text={
                    <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                      {formatCurrency(totaleImporto)}
                    </Typography>
                  }
                  backgroundColor="background.primaryLight"
                  borderRadius="4px"
                />
              </Stack>

              <Stack sx={{ mt: 2 }}>
                {noleggiImportoProgressBar.map(noleggio => (
                  <Stack
                    key={noleggio.codice}
                    flexDirection="row"
                    justifyContent={'start'}
                    alignItems={'center'}
                    sx={{ mb: 1 }}
                  >
                    <Typography sx={{ flex: 1 }}>{noleggio.codice}</Typography>
                    <Box sx={{ flex: 4, mr: 1 }}>
                      <LinearProgress
                        sx={{
                          height: 10,
                          borderRadius: 0,
                        }}
                        variant="determinate"
                        value={noleggio.percentuale}
                      />
                    </Box>
                    <Typography sx={{ flex: 1 }}>
                      {formatCurrency(noleggio.importo)}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
