import {
  ArrowBackIos,
  ArrowForwardIos,
  ChevronRight,
  ContentCopy,
  Description,
  Edit,
} from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  Grid2 as Grid,
  Stack,
  Typography,
  IconButton,
  Divider,
  Stepper,
  Step,
  StepLabel,
  Button,
  LinearProgress,
  Alert,
  CircularProgress,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import BeniTable from './BeniTable';
import DocumentiGrid from './DocumentiGrid';
import {
  downloadCertificatoIntervento,
  downloadDocumento,
  getInterventi,
  inoltraRichiestaModifica,
} from '../../../redux/noleggi/api';
import { useEffect, useState } from 'react';
import LoadingMask from '../../../components/LoadingMask';
import { useDispatch } from 'react-redux';
import { setNoleggioClone } from '../../../redux/noleggi/slice';
import { showSnackbar } from '../../../redux/app/slice';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import DialogIntervento from './DialogIntervento';
import { downloadBlob } from '../../../utils/download';
import DialogRichiestaModificaNoleggio from './DialogRichiestaModificaNoleggio';
import { formatCurrency } from '../../../utils/formatNumber';
import { formatStringDate } from '../../../utils/formadDate';
import RichiestaModificaSvg from '../../../assets/img/RichiestaModifica.svg';

export default function PraticaNoleggio({ noleggioData, setViewMode }) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialogRichiestaModificaOpen, setDialogRichiestaModificaOpen] =
    useState(false);
  const [dialogManutazioneOpen, setDialogManutazioneOpen] = useState(false);
  const [selectedIntervento, setSelectedIntervento] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isInterventiLoading, setIsInterventiLoading] = useState(false);
  const [interventi, setInterventi] = useState(noleggioData?.interventi || []);
  // interventi.sort((a, b) => new Date(b.data) - new Date(a.data));

  let steps = [
    { key: 1, title: 'PROPOSTA IN BOZZA', subtitle: '' },
    { key: 2, title: 'PRATICA INOLTRATA', subtitle: 'In Valutazione' },
    { key: 2.5, title: 'PRATICA DECLINATA', subtitle: '' },
    { key: 3, title: 'PROPOSTA INOLTRATA', subtitle: 'In Perfezionamento' },
    { key: 4, title: 'PROPOSTA INOLTRATA', subtitle: 'In Attesa di Firma' },
    { key: 5, title: 'PRATICA CONFERMATA', subtitle: '' },
    {
      key: 6,
      title: 'MATERIALE CONSEGNATO',
      subtitle: 'In attesa di attivazione del Noleggio',
    },
  ];

  if (noleggioData.id_stato != 'RF') {
    steps = steps.filter(step => step.key != 2.5);
  } else {
    steps = steps.filter(step => step.key <= 2.5);
  }

  const onClona = () => {
    dispatch(setNoleggioClone(noleggioData));
    navigate('/noleggi/noleggio/clone', { replace: true });
  };

  const onCompleta = () => {
    setViewMode('edit');
  };

  const onDownloadDocumento = async (name, id) => {
    setIsLoading(true);
    try {
      const pdfData = await downloadDocumento(id);
      downloadBlob(pdfData, 'application/pdf', name + '.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  let activeStep = 1;
  if (noleggioData.id_stato === 'IC' && noleggioData.id_step < 4) {
    activeStep = 1;
  } else if (noleggioData.id_stato === 'IC' && noleggioData.id_step >= 4) {
    activeStep = 2;
  } else if (noleggioData.id_stato === 'FD' || noleggioData.id_stato === 'VD') {
    activeStep = 3;
  } else if (noleggioData.id_stato === 'CF') {
    activeStep = 5;
  } else if (noleggioData.id_stato === 'RF') {
    activeStep = 2.5;
  }

  useEffect(() => {
    const state = location.state;
    if (
      state?.id_richiesta_modifica &&
      noleggioData.richiesta_modifica?.id === state?.id_richiesta_modifica
    ) {
      setDialogRichiestaModificaOpen(true);
    }
  }, []);

  const giorniAScadenza = () => {
    if (noleggioData.data_scadenza == null) {
      return '';
    }
    const data = new Date();
    const dataScadenza = new Date(noleggioData.data_scadenza);
    const diffTime = Math.abs(dataScadenza - data);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const getIndirizzoLocazione = () => {
    let indirizzo = '';
    if (noleggioData?.azienda?.locazione_indirizzo != null) {
      indirizzo += noleggioData?.azienda?.locazione_indirizzo;
    }
    if (noleggioData?.azienda?.locazione_civico != null) {
      indirizzo += ', ' + noleggioData?.azienda?.locazione_civico;
    }
    if (noleggioData?.azienda?.locazione_cap != null) {
      indirizzo += ' - ' + noleggioData?.azienda?.locazione_cap;
    }
    if (noleggioData?.azienda?.locazione_citta != null) {
      indirizzo += ' ' + noleggioData?.azienda?.locazione_citta;
    }
    return indirizzo;
  };

  const getIndirizzoSede = () => {
    let indirizzo = '';
    if (noleggioData?.azienda?.sede_indirizzo != null) {
      indirizzo += noleggioData?.azienda?.sede_indirizzo;
    }
    if (noleggioData?.azienda?.sede_civico != null) {
      indirizzo += ', ' + noleggioData?.azienda?.sede_civico;
    }
    if (noleggioData?.azienda?.sede_cap != null) {
      indirizzo += ' - ' + noleggioData?.azienda?.sede_cap;
    }
    if (noleggioData?.azienda?.sede_citta != null) {
      indirizzo += ' ' + noleggioData?.azienda?.sede_citta;
    }
    return indirizzo;
  };

  const onNewIntervento = () => {
    setSelectedIntervento(null);
    setDialogManutazioneOpen(true);
  };

  const onViewIntervento = intervento => {
    setSelectedIntervento(intervento);
    setDialogManutazioneOpen(true);
  };

  const onInterventoSaved = () => {
    setDialogManutazioneOpen(false);
    caricaInterventi();
  };

  const onInterventoDeleted = () => {
    setDialogManutazioneOpen(false);
    caricaInterventi();
  };

  const onDownloadCertificato = async id => {
    setIsLoading(true);
    try {
      const blobData = await downloadCertificatoIntervento(id);
      downloadBlob(blobData, 'application/pdf', 'certificato_manutenzione.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const caricaInterventi = async () => {
    setIsInterventiLoading(true);
    try {
      const response = await getInterventi(noleggioData.id);
      setInterventi(response);
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsInterventiLoading(false);
    }
  };

  const onRichiediModifica = () => {
    setDialogRichiestaModificaOpen(true);
  };

  const onModificaClick = () => {
    if (noleggioData.richiesta_modifica.id_stato == 'DC') {
      setDialogRichiestaModificaOpen(true);
    } else {
      navigate('/richieste-modifica/edit', {
        state: { id: noleggioData.richiesta_modifica.id },
      });
    }
  };

  const onInoltraModifica = async () => {
    setIsLoading(true);
    try {
      const response = await inoltraRichiestaModifica(
        noleggioData.richiesta_modifica.id,
        { flg_firma_digitale: 0 }
      );
      noleggioData.richiesta_modifica = response;
      setDialogRichiestaModificaOpen(false);
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      {isLoading && <LoadingMask />}
      <Stack
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          backgroundColor: 'background.primaryLight',
          p: 2,
          borderRadius: '4px',
        }}
      >
        <Stack flexDirection="row" alignItems="center">
          <IconButton
            sx={{ border: 'none' }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography component="h4" variant="body">
              Pratica di Noleggio
            </Typography>
            <Stack flexDirection="row">
              <Typography component="h3" variant="body">
                Operazione {noleggioData.codice} -
              </Typography>
              <Typography color="primary" component="h3" variant="body">
                &nbsp;Azienda {noleggioData?.azienda?.ragione_sociale}
              </Typography>
            </Stack>
          </Box>

          <Divider sx={{ ml: 4, mr: 4 }} orientation="vertical" flexItem />

          <Box>
            <Typography variant="body">Utente</Typography>
            <Typography component="h4" variant="body">
              {noleggioData?.utente}
            </Typography>
          </Box>
        </Stack>

        <Stack flexDirection="row" alignItems={'center'}>
          {(noleggioData.id_stato == 'IB' || noleggioData.id_stato == 'DC') && (
            <Button
              variant="contained"
              sx={{ mr: 2, fontFamily: 'Poppins-SemiBold' }}
              size="small"
              onClick={onCompleta}
              startIcon={<Edit />}
            >
              COMPLETA PROPOSTA
            </Button>
          )}
          {noleggioData.id_stato == 'TE' &&
            !noleggioData.richiesta_modifica && (
              <Button
                variant="contained"
                sx={{ mr: 2, fontFamily: 'Poppins-SemiBold' }}
                size="small"
                onClick={onRichiediModifica}
                startIcon={<Edit />}
              >
                RICHIEDI MODIFICA
              </Button>
            )}
          <Button
            variant="contained"
            color="white"
            sx={{ mr: 2, fontFamily: 'Poppins-SemiBold' }}
            size="small"
            onClick={onClona}
            startIcon={<ContentCopy />}
          >
            CLONA PROPOSTA
          </Button>
          {noleggioData.id_stato == 'TE' && noleggioData.richiesta_modifica && (
            <Stack
              flexDirection={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ backgroundColor: 'white' }}
            >
              <Stack
                sx={{ pl: 1, pr: 1, pt: 2, pb: 2, backgroundColor: '#103188' }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <img src={RichiestaModificaSvg} height={15} alt="" />
              </Stack>
              <Stack sx={{ pl: 2, pr: 3 }}>
                <Typography
                  component="h4"
                  sx={{ fontFamily: 'Poppins-SemiBold', color: '#103188' }}
                >
                  Richiesta di Modifica
                </Typography>
                <Typography component="h5" sx={{ color: '#103188' }}>
                  {noleggioData.richiesta_modifica.nome_stato}
                </Typography>
              </Stack>

              <Stack
                alignItems={'center'}
                justifyContent={'center'}
                sx={{ pl: 2, pr: 2 }}
              >
                <ChevronRight
                  sx={{ color: '#446BD5', cursor: 'pointer' }}
                  onClick={onModificaClick}
                />
              </Stack>
            </Stack>
            // <Button
            //   variant="contained"
            //   sx={{ mr: 2, fontFamily: 'Poppins-SemiBold' }}
            //   size="small"
            //   onClick={onRichiediModifica}
            //   startIcon={<Edit />}
            // >
            //   RICHIEDI MODIFICA
            // </Button>
          )}
        </Stack>
      </Stack>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, md: 6 }}>
          <Card sx={{ p: 2, backgroundColor: 'background.default' }}>
            <Typography component="h4" variant="body" color="primary">
              Dati Operazione
            </Typography>
            <CardContent sx={{ mt: 2 }}>
              <Grid container spacing={1}>
                <Grid size={{ xs: 12, md: 3 }}>Canone Mensile:</Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography
                    sx={{ fontFamily: 'Poppins-SemiBold' }}
                    color="primary"
                    variant="h5"
                  >
                    {formatCurrency(noleggioData?.rata_no_iva)} (senza IVA)
                  </Typography>
                  <Typography>
                    {formatCurrency(noleggioData?.rata)} (IVA inclusa)
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>Importo:</Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography
                    sx={{ fontFamily: 'Poppins-SemiBold' }}
                    variant="h5"
                  >
                    {formatCurrency(noleggioData?.importo)}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 3 }}>Assicurazione:</Grid>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography>
                    {noleggioData?.flg_assicurazione ? 'ATTIVA' : 'NON ATTIVA'}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>Durata:</Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Typography>{noleggioData?.durata_mesi} mesi</Typography>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={1}>
                <Grid size={{ xs: 12, md: 2 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    {noleggioData.id_stato === 'TE' ||
                    noleggioData.id_stato === 'FI'
                      ? 'Durata'
                      : 'Stato'}
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 4.5 }}>
                  {noleggioData.id_stato === 'SC' && (
                    <Typography
                      color="error.main"
                      sx={{ fontFamily: 'Poppins-SemiBold' }}
                    >
                      In Bozza - Scaduta
                    </Typography>
                  )}
                  {noleggioData.id_stato === 'RF' && (
                    <>
                      <Typography
                        color="error.main"
                        sx={{ fontFamily: 'Poppins-SemiBold' }}
                      >
                        Declinata
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ display: 'block', lineHeight: 1, fontSize: 11 }}
                      >
                        Il noleggiatore non rietene la proposta conforme per il
                        noleggio
                      </Typography>
                    </>
                  )}
                  {noleggioData.id_stato === 'CF' && (
                    <>
                      <Typography
                        color="success"
                        sx={{ fontFamily: 'Poppins-SemiBold' }}
                      >
                        Confermata
                      </Typography>
                    </>
                  )}
                  {(noleggioData.id_stato === 'IB' ||
                    noleggioData.id_stato === 'DC') && (
                    <>
                      <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                        {' '}
                        In Bozza
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ display: 'block', lineHeight: 1, fontSize: 11 }}
                      >
                        Hai {giorniAScadenza()} giorni per completare la
                        proposta di noleggio
                      </Typography>
                    </>
                  )}
                  {(noleggioData.id_stato === 'IC' ||
                    noleggioData.id_stato === 'VD' ||
                    noleggioData.id_stato == 'FD') && (
                    <>
                      <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                        {' '}
                        In Attesa di Firma
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{ display: 'block', lineHeight: 1, fontSize: 11 }}
                      >
                        Il noleggiatore sta valutando la tua proposta di
                        noleggio
                      </Typography>
                    </>
                  )}
                  {(noleggioData.id_stato === 'TE' ||
                    noleggioData.id_stato === 'FI') && (
                    <>
                      <Stack
                        flexDirection="row"
                        justifyContent={'space-between'}
                      >
                        <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                          {' '}
                          Data Inizio Noleggio:
                        </Typography>
                        <Typography>
                          {noleggioData.data_inizio != null
                            ? formatStringDate(noleggioData.data_inizio)
                            : ''}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="caption"
                        sx={{ display: 'block', lineHeight: 1, fontSize: 11 }}
                      >
                        Indicata dalla notifica di consegna del materiale
                      </Typography>
                    </>
                  )}
                </Grid>
                <Grid size={{ xs: 12, md: 1 }}></Grid>
                <Grid size={{ xs: 12, md: 4.5 }}>
                  {(noleggioData.id_stato == 'IC' ||
                    noleggioData.id_stato == 'FD' ||
                    noleggioData.id_stato == 'RF') && (
                    <>
                      <Stack
                        flexDirection="row"
                        justifyContent={'space-between'}
                      >
                        <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                          {' '}
                          Data Inoltro Pratica:
                        </Typography>
                        <Typography>
                          {noleggioData.data_inoltro != null
                            ? formatStringDate(noleggioData.data_inoltro)
                            : ''}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="caption"
                        sx={{ display: 'block', lineHeight: 1, fontSize: 11 }}
                      >
                        Indicata dalla notifica di consegna del materiale
                      </Typography>
                    </>
                  )}
                  {(noleggioData.id_stato === 'TE' ||
                    noleggioData.id_stato === 'FI') && (
                    <>
                      <Stack
                        flexDirection="row"
                        justifyContent={'space-between'}
                      >
                        <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                          {' '}
                          Fine Noleggio:
                        </Typography>
                        <Typography>
                          {noleggioData.data_fine != null
                            ? formatStringDate(noleggioData.data_fine)
                            : ''}
                        </Typography>
                      </Stack>
                      <Typography
                        variant="caption"
                        sx={{ display: 'block', lineHeight: 1, fontSize: 11 }}
                      >
                        Indicata dall'acquisto dei beni noleggiati
                      </Typography>
                    </>
                  )}
                </Grid>
              </Grid>

              <Box sx={{ width: '100%', mt: 4 }}>
                {(noleggioData.id_stato === 'TE' ||
                  noleggioData.id_stato === 'FI') && (
                  <>
                    <Box sx={{ display: 'flex', alignItems: 'center', px: 4 }}>
                      <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                          variant="determinate"
                          value={noleggioData.percentuale_avanzamento}
                        />
                      </Box>
                      <Box sx={{ minWidth: 35 }}>
                        <Typography
                          variant="body2"
                          sx={{ color: 'text.secondary' }}
                        >
                          {`${Math.round(noleggioData.percentuale_avanzamento)}%`}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                )}
                {(noleggioData.id_stato == 'IC' ||
                  noleggioData.id_stato == 'FD' ||
                  noleggioData.id_stato == 'CF' ||
                  noleggioData.id_stato == 'RF') && (
                  <Stepper
                    activeStep={activeStep}
                    alternativeLabel
                    sx={{ overflowY: 'auto', overflowX: 'hidden', py: 1 }}
                  >
                    {steps.map(label => (
                      <Step key={label.key}>
                        <StepLabel error={label.key === 2.5}>
                          <Typography
                            sx={{
                              fontSize: 12,
                              display: 'block',
                              lineHeight: 1.1,
                            }}
                          >
                            {label.title}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: 10,
                              display: 'block',
                              lineHeight: 1.1,
                              mt: 1,
                            }}
                          >
                            {label.subtitle}
                          </Typography>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}

                {noleggioData.flg_scadenza && (
                  <Alert severity="info" sx={{ mt: 2 }}>
                    <Typography variant="caption">
                      Il Noleggio è in scadenza
                    </Typography>
                  </Alert>
                )}
              </Box>

              <Divider sx={{ mt: 2, mb: 2 }} />

              <Typography component="h4" variant="body" color="primary">
                Dati Cliente
              </Typography>
              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    Dati Azienda
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Stack flexDirection="row">
                    <Stack>
                      <Typography>Ragione Sociale:</Typography>
                      <Typography>Codice Fiscale:</Typography>
                      <Typography>P.IVA:</Typography>
                    </Stack>
                    <Stack sx={{ ml: 2 }}>
                      <Typography>
                        {noleggioData?.azienda?.ragione_sociale}
                      </Typography>
                      <Typography>{noleggioData?.azienda?.cf}</Typography>
                      <Typography>{noleggioData?.azienda?.piva}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    Contatti
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Stack flexDirection="row">
                    <Stack>
                      <Typography>Email:</Typography>
                      <Typography>Telefono:</Typography>
                    </Stack>
                    <Stack sx={{ ml: 2 }}>
                      <Typography>{noleggioData?.azienda?.mail}</Typography>
                      <Typography>{noleggioData?.azienda?.telefono}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    Indirizzo
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Stack flexDirection="row">
                    <Stack>
                      <Typography>Ind. Locazione:</Typography>
                      <Typography>Ind. Sede:</Typography>
                    </Stack>
                    <Stack sx={{ ml: 2 }}>
                      <Typography>{getIndirizzoLocazione()}</Typography>
                      <Typography>{getIndirizzoSede()}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>

              <Divider sx={{ mt: 2, mb: 2 }} />

              <Grid container spacing={1} sx={{ mt: 1 }}>
                <Grid size={{ xs: 12, md: 3 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    Dettagli Pagamento
                  </Typography>
                </Grid>
                <Grid size={{ xs: 12, md: 9 }}>
                  <Stack flexDirection="row">
                    <Stack>
                      <Typography>SSD:</Typography>
                      <Typography>IBAN:</Typography>
                    </Stack>
                    <Stack sx={{ ml: 2 }}>
                      <Typography>
                        {noleggioData?.azienda?.iban != null &&
                        noleggioData?.azienda?.iban != ''
                          ? 'ATTIVO'
                          : 'NON ATTIVO'}
                      </Typography>
                      <Typography>{noleggioData?.azienda?.iban}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <Card sx={{ p: 2, backgroundColor: 'background.default' }}>
            <Typography component="h4" variant="body">
              Elenco Beni Aggiunti
            </Typography>
            <CardContent sx={{ mt: 2 }}>
              <BeniTable rows={noleggioData?.dettagli || []} readOnly={true} />
            </CardContent>
          </Card>
          <Card sx={{ p: 2, backgroundColor: 'background.default', mt: 1 }}>
            <Typography component="h4" variant="body">
              Documenti
            </Typography>
            <CardContent sx={{ mt: 2 }}>
              <DocumentiGrid
                data={noleggioData?.documenti ?? []}
                onDownload={onDownloadDocumento}
              />
            </CardContent>
          </Card>

          {(noleggioData.id_stato === 'TE' ||
            noleggioData.id_stato === 'FI') && (
            <Card sx={{ p: 2, backgroundColor: 'background.default', mt: 1 }}>
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography component="h4" variant="body">
                  Interventi di Manutenzione
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  onClick={onNewIntervento}
                  sx={{ fontFamily: 'Poppins-SemiBold' }}
                >
                  AGGIUNGI NOTA MANUTENZIONE
                </Button>
              </Stack>
              <CardContent sx={{ mt: 2 }}>
                {isInterventiLoading && (
                  <Stack
                    flexDirection="row"
                    alignItems="center"
                    justifyContent={'center'}
                  >
                    <CircularProgress size={30} />
                  </Stack>
                )}
                {!isInterventiLoading && (
                  <Box>
                    {interventi.length > 0 && (
                      <Timeline
                        position="right"
                        sx={{
                          [`& .${timelineItemClasses.root}:before`]: {
                            flex: 0,
                            padding: 0,
                          },
                        }}
                      >
                        {interventi.map((intervento, index) => (
                          <TimelineItem key={index}>
                            <TimelineSeparator>
                              <TimelineDot color="primary" />
                              {index < interventi.length - 1 && (
                                <TimelineConnector
                                  sx={{ backgroundColor: 'primary.main' }}
                                />
                              )}
                            </TimelineSeparator>
                            <TimelineContent>
                              <Stack flexDirection="row" alignItems="center">
                                <Box>
                                  <Typography
                                    sx={{ fontFamily: 'Poppins-SemiBold' }}
                                  >
                                    Manutenzione Ordinaria
                                  </Typography>
                                  <Typography variant="caption">
                                    {formatStringDate(intervento.data)}
                                  </Typography>
                                </Box>
                                {intervento.has_image ? (
                                  <Stack
                                    flexDirection="row"
                                    alignItems={'center'}
                                    sx={{ ml: 2 }}
                                  >
                                    <IconButton
                                      sx={{
                                        border: 'none',
                                        color: 'primary.main',
                                        mr: 1,
                                      }}
                                      onClick={() => {
                                        onDownloadCertificato(intervento.id);
                                      }}
                                    >
                                      <Description />
                                    </IconButton>
                                    Certificato di Manutenzione
                                  </Stack>
                                ) : (
                                  <> </>
                                )}
                                <Box sx={{ flexGrow: 1 }} />
                                <IconButton
                                  size="small"
                                  sx={{ border: 'none' }}
                                  onClick={() => onViewIntervento(intervento)}
                                >
                                  <ArrowForwardIos />
                                </IconButton>
                              </Stack>
                              {index < interventi.length - 1 && (
                                <Divider sx={{ mt: 2 }} />
                              )}
                            </TimelineContent>
                          </TimelineItem>
                        ))}
                      </Timeline>
                    )}
                    {interventi.length == 0 && (
                      <Typography variant="caption">
                        Nessun intervento di manutenzione presente
                      </Typography>
                    )}
                  </Box>
                )}
              </CardContent>
            </Card>
          )}
        </Grid>
      </Grid>

      <DialogIntervento
        idRichiesta={noleggioData.id}
        intervento={selectedIntervento}
        open={dialogManutazioneOpen}
        onClose={() => {
          setDialogManutazioneOpen(false);
        }}
        onSaved={onInterventoSaved}
        onDeleted={onInterventoDeleted}
        onDownloadCertificato={onDownloadCertificato}
      />

      <DialogRichiestaModificaNoleggio
        noleggioData={noleggioData}
        open={dialogRichiestaModificaOpen}
        onClose={() => {
          setDialogRichiestaModificaOpen(false);
        }}
        onSaved={onInoltraModifica}
      />
    </Box>
  );
}
