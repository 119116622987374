import { Card, Stack, Typography } from '@mui/material';
import LoadingMask from '../../../components/LoadingMask';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import TotaleComponent from './TotaleComponent';
import GraficoTorta from './GraficoTorta';
import useOperazioniData from '../hook/useOperazioniData';

export default function OperazioniPanel() {
  const {
    isLoadingOperazioniData,
    operazioniData,
    loadingErrorOperazioniData,
  } = useOperazioniData();

  return (
    <Stack sx={{ position: 'relative' }}>
      <Card sx={{ backgroundColor: '#FAFAFA' }}>
        <Typography component="h3" variant="h5" sx={{ color: '#284BAA' }}>
          Operazioni
        </Typography>
        {operazioniData && (
          <Stack flexDirection="row">
            <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
              <TotaleComponent
                titolo={'Tot. Operazioni Attive'}
                sottotitolo={'N. di Operazioni Attive Attuali'}
                value={operazioniData.attive?.counter}
              />
              <GraficoTorta value={operazioniData.attive?.stati} />
            </Stack>
            <Stack sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}>
              <TotaleComponent
                titolo={'Tot. Operazioni NON Attive'}
                sottotitolo={'N. di Operazioni Non Attive Attuali'}
                value={operazioniData.nonAttive?.counter}
              />
              <GraficoTorta value={operazioniData.nonAttive?.stati} />
            </Stack>
          </Stack>
        )}
      </Card>
      {loadingErrorOperazioniData != null && (
        <ActionErrorMessage error={loadingErrorOperazioniData} />
      )}
      {isLoadingOperazioniData ? <LoadingMask /> : <></>}
    </Stack>
  );
}
