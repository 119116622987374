import {
  Box,
  Button,
  Card,
  Divider,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import {
  ArrowBackIos,
  Block,
  CheckCircle,
  ChevronRight,
  Download,
  Error,
  Inventory2,
  PendingActions,
} from '@mui/icons-material';
import { useState } from 'react';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import LoadingMask from '../../../components/LoadingMask';
import { downloadBlob } from '../../../utils/download';
import { showSnackbar } from '../../../redux/app/slice';
import { useDispatch } from 'react-redux';
import {
  downloadDocumentoModifica,
  rifiutaRichiestaModifica,
} from '../../../redux/noleggi/api';
import { formatCurrency } from '../../../utils/formatNumber';
import { formatStringDate } from '../../../utils/formadDate';
import StatoModificaSvg from '../../../assets/img/StatoModifica.svg';
import BeniTable from '../../Noleggi/components/BeniTable';

export default function RichiestaModificaCard({ richiestaModificaData }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const getHeader = () => {
    if (richiestaModificaData) {
      switch (richiestaModificaData.id_stato) {
        case 'FD':
          return (
            <Stack flexDirection="row" sx={{ p: 2 }} alignItems={'center'}>
              <CheckCircle sx={{ color: '#446BD5', fontSize: 28 }} />
              <Stack sx={{ ml: 2, mr: 4 }}>
                <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                  Le modifiche sono state approvate
                </Typography>
                <Typography>
                  Per attivare le modifiche, firma il documento allegato.
                </Typography>
              </Stack>
              {richiestaModificaData.flg_firma_digitale ? (
                <Button variant="contained" size="small" sx={{ p: 2 }}>
                  <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                    FIRMA DOCUMENTO
                  </Typography>
                </Button>
              ) : richiestaModificaData.id_documento ? (
                <Button
                  variant="contained"
                  onClick={() =>
                    onDownloadDocumento(richiestaModificaData.id_documento)
                  }
                  size="small"
                  sx={{ p: 2 }}
                >
                  <Download />
                  <Typography sx={{ ml: 2, fontFamily: 'Poppins-SemiBold' }}>
                    SCARICA DOCUMENTO
                  </Typography>
                </Button>
              ) : (
                <></>
              )}
              <Button
                onClick={onRifiutaClick}
                size="small"
                sx={{
                  ml: 4,
                  color: '#113188',
                  border: '2px solid #113188',
                  p: 2,
                }}
              >
                <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                  RIFIUTA RICHIESTA
                </Typography>
              </Button>
              <Box sx={{ flex: 1 }} />
              {getVisualizzaPratica()}
            </Stack>
          );
        case 'IC':
          return (
            <Stack flexDirection="row" sx={{ p: 2 }} alignItems={'center'}>
              <PendingActions sx={{ color: '#446BD5', fontSize: 28 }} />
              <Stack sx={{ ml: 2, mr: 4 }}>
                <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                  Le modifiche sono in fase di valutazione
                </Typography>
                <Typography>
                  Quando le modifiche saranno valutate, riceverete una notifica
                  relativa all'aggiornamento dello stato.
                </Typography>
              </Stack>
            </Stack>
          );
        case 'DT':
          return (
            <Stack flexDirection="row" sx={{ p: 2 }} alignItems={'center'}>
              <Inventory2 sx={{ color: 'green', fontSize: 28 }} />
              <Stack sx={{ ml: 2, mr: 4 }}>
                <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                  La richiesta di modifiche è in attesa del ritiro della merce.
                </Typography>
                <Typography>
                  Per terminare e aggiornare il Contratto di Noleggio, il
                  materiale rescisso deve essere ritirato.
                </Typography>
              </Stack>
              <Box sx={{ flex: 1 }} />
              {getVisualizzaPratica()}
            </Stack>
          );
        case 'TE':
          return (
            <Stack flexDirection="row" sx={{ p: 2 }} alignItems={'center'}>
              <CheckCircle sx={{ color: 'green', fontSize: 28 }} />
              <Stack sx={{ ml: 2, mr: 4 }}>
                <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                  La richiesta di modifiche è terminata correttamente.
                </Typography>
                <Typography>
                  Il materiale rescisso è stato ritirato, è stata addebitata la
                  penale e le nuove rate sono state aggiornate.
                </Typography>
              </Stack>
              <Box sx={{ flex: 1 }} />
              {getVisualizzaPratica()}
            </Stack>
          );
        case 'RC':
          return (
            <Stack flexDirection="row" sx={{ p: 2 }} alignItems={'center'}>
              <Block sx={{ color: 'red', fontSize: 28 }} />
              <Stack sx={{ ml: 2, mr: 4 }}>
                <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                  La richiesta di modifiche è stata declinata.
                </Typography>
                <Typography>
                  La richiesta di modifiche è stata declinata dall'utente.
                </Typography>
              </Stack>
              <Box sx={{ flex: 1 }} />
              {getVisualizzaPratica()}
            </Stack>
          );
        case 'RF':
          return (
            <Stack flexDirection="row" sx={{ p: 2 }} alignItems={'center'}>
              <Block sx={{ color: 'red', fontSize: 28 }} />
              <Stack sx={{ ml: 2, mr: 4 }}>
                <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                  La richiesta di modifiche è stata declinata.
                </Typography>
                <Typography>
                  La richiesta di modifiche è stata declinata dal Noleggiatore.
                </Typography>
              </Stack>
              <Box sx={{ flex: 1 }} />
              {getVisualizzaPratica()}
            </Stack>
          );
      }
    }
  };

  const getVisualizzaPratica = () => {
    return (
      <Stack
        onClick={onVisualizzaPraticaClick}
        flexDirection={'row'}
        alignItems={'center'}
        sx={{
          color: '#113188',
          cursor: 'pointer',
        }}
      >
        <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
          Visualizza Pratica
        </Typography>
        <ChevronRight sx={{ fontSize: 28 }} />
      </Stack>
    );
  };

  const onDownloadDocumento = async id => {
    setIsLoading(true);
    try {
      const pdfData = await downloadDocumentoModifica(id);
      downloadBlob(pdfData, 'application/pdf', 'Rescissione.pdf');
    } catch (error) {
      useDispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onRifiutaClick = async () => {
    setIsLoading(true);
    try {
      await rifiutaRichiestaModifica(richiestaModificaData.id);
      navigate(-1);
    } catch (error) {
      setLoadingError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onVisualizzaPraticaClick = () => {
    navigate('/noleggi/noleggio/edit', {
      state: { id: richiestaModificaData.id_richiesta },
    });
  };

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      <Stack
        justifyContent="space-between"
        flexDirection="row"
        sx={{
          backgroundColor: 'background.primaryLight',
          p: 2,
          borderRadius: '4px',
        }}
      >
        <Stack flexDirection="row" alignItems="center">
          <IconButton
            sx={{ border: 'none' }}
            size="small"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIos />
          </IconButton>
          <Box sx={{ ml: 1 }}>
            <Typography component="h4" variant="body">
              Richiesta di Modifica
            </Typography>
            <Stack flexDirection="row">
              <Typography component="h3" variant="body">
                Operazione {richiestaModificaData?.codice_operazione} -{' '}
                {richiestaModificaData?.azienda}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      </Stack>

      <Stack sx={{ backgroundColor: 'background.default', p: 2 }}>
        {getHeader()}
        {loadingError != null && <ActionErrorMessage error={loadingError} />}
        <Divider sx={{ mt: 2, mb: 2 }} />
        <Grid container spacing={1}>
          <Grid size={{ xs: 6, sm: 4 }}>
            <Card
              sx={{
                backgroundColor: 'background.default',
                position: 'relative',
                overflow: 'unset',
              }}
            >
              <Stack sx={{ pl: 2, pr: 2, pb: 3 }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, md: 12 }}>
                    <Typography component="h4" variant="body">
                      Dati Operazione Precedente
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }}>Importo Totale:</Grid>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                      {formatCurrency(
                        richiestaModificaData?.dati_precedenti?.importo
                      )}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }}>Canone Mensile:</Grid>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography
                      sx={{ fontFamily: 'Poppins-SemiBold' }}
                      color="primary"
                    >
                      {formatCurrency(
                        richiestaModificaData?.dati_precedenti?.canone_no_iva
                      )}{' '}
                      (senza IVA)
                    </Typography>
                    <Typography>
                      {formatCurrency(
                        richiestaModificaData?.dati_precedenti?.canone_iva
                      )}{' '}
                      (IVA inclusa)
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }}>Durata:</Grid>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography>
                      {richiestaModificaData?.dati_precedenti?.durata} mesi
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <Typography> Data Inizio Noleggio:</Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 2 }}>
                    <Typography>
                      {richiestaModificaData?.dati_precedenti?.data_inizio !=
                      null
                        ? formatStringDate(
                            richiestaModificaData?.dati_precedenti?.data_inizio
                          )
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <Typography> Data Fine Noleggio:</Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 2 }}>
                    <Typography>
                      {richiestaModificaData?.dati_precedenti?.data_fine != null
                        ? formatStringDate(
                            richiestaModificaData?.dati_precedenti?.data_fine
                          )
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }}>Assicurazione:</Grid>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography>
                      {richiestaModificaData?.dati_precedenti?.flg_assicurazione
                        ? 'ATTIVA'
                        : 'NON ATTIVA'}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
              <Stack
                sx={{
                  position: 'absolute',
                  top: 0,
                  bottom: 0,
                  right: -20,
                  zIndex: 1000000,
                }}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <img src={StatoModificaSvg} alt="" />
              </Stack>
            </Card>
          </Grid>
          <Grid size={{ xs: 6, sm: 8 }}>
            <Card sx={{ backgroundColor: '#F0F6FD' }}>
              <Stack sx={{ pl: 2, pr: 2, pb: 3 }}>
                <Grid container spacing={2}>
                  <Grid size={{ xs: 12, md: 12 }}>
                    <Typography component="h4" variant="body">
                      Dati Operazione Modifica
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }}>Importo Totale:</Grid>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                      {formatCurrency(
                        richiestaModificaData?.dati_modifica?.importo
                      )}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }}>Canone Mensile:</Grid>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography
                      sx={{ fontFamily: 'Poppins-SemiBold' }}
                      color="primary"
                    >
                      {formatCurrency(
                        richiestaModificaData?.dati_modifica?.canone_no_iva
                      )}{' '}
                      (senza IVA)
                    </Typography>
                    <Typography>
                      {formatCurrency(
                        richiestaModificaData?.dati_modifica?.canone_iva
                      )}{' '}
                      (IVA inclusa)
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }}>Durata:</Grid>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography>
                      {richiestaModificaData?.dati_modifica?.durata} mesi
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <Typography> Data Inizio Noleggio:</Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 2 }}>
                    <Typography>
                      {richiestaModificaData?.dati_modifica?.data_inizio != null
                        ? formatStringDate(
                            richiestaModificaData?.dati_modifica?.data_inizio
                          )
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 4 }}>
                    <Typography> Data Fine Noleggio:</Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 2 }}>
                    <Typography>
                      {richiestaModificaData?.dati_modifica?.data_fine != null
                        ? formatStringDate(
                            richiestaModificaData?.dati_modifica?.data_fine
                          )
                        : ''}
                    </Typography>
                  </Grid>
                  <Grid size={{ xs: 12, md: 3 }}>Assicurazione:</Grid>
                  <Grid size={{ xs: 12, md: 9 }}>
                    <Typography>
                      {richiestaModificaData?.dati_modifica?.flg_assicurazione
                        ? 'ATTIVA'
                        : 'NON ATTIVA'}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>
            </Card>
            <Card sx={{ mt: 2, backgroundColor: '#F0F6FD' }}>
              <Stack flexDirection={'row'} sx={{ pl: 2, pr: 2, pb: 3 }}>
                <Error sx={{ color: '#446BD5', fontSize: 28 }} />
                <Stack sx={{ ml: 2 }}>
                  <Typography
                    component="h2"
                    sx={{
                      fontFamily: 'Poppins-SemiBold',
                      color: '#446BD5',
                      fontSize: 18,
                    }}
                  >
                    Costo Penale
                  </Typography>
                  <Typography>
                    La modifica del contratto di Noleggio comporta un pagamento
                    di una penale del valore di{' '}
                    <Typography
                      component={'span'}
                      sx={{ fontFamily: 'Poppins-SemiBold', fontSize: 18 }}
                    >
                      {formatCurrency(richiestaModificaData?.penale)}
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
            </Card>
            <Stack sx={{ mt: 2, p: 2 }}>
              <Typography component="h4" variant="body">
                Elenco Beni Rescissi
              </Typography>
              <Box sx={{ mt: 2 }}>
                <BeniTable
                  rows={richiestaModificaData?.dettagli || []}
                  readOnly={true}
                />
                {/* <BeniTable
                  rows={noleggioData?.dettagli || []}
                  readOnly={true}
                /> */}
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      {isLoading ? <LoadingMask /> : <></>}
    </Box>
  );
}
