import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawerOpen: false,
  drawerComunicazioniOpen: false,
  snackbarConfig: null,
  alertDialogConfig: null,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleDrawer: state => {
      state.drawerOpen = !state.drawerOpen;
      if (state.drawerComunicazioniOpen)
        state.drawerComunicazioniOpen = !state.drawerComunicazioniOpen;
    },
    toggleDrawerComunicazioni: state => {
      state.drawerComunicazioniOpen = !state.drawerComunicazioniOpen;
      if (state.drawerOpen) state.drawerOpen = !state.drawerOpen;
    },
    showSnackbar: (state, action) => {
      state.snackbarConfig = {
        open: true,
        message: '',
        severity: 'info', // Può essere: 'success', 'error', 'warning', 'info'
        duration: 3000,
        position: { vertical: 'top', horizontal: 'center' },
        ...action.payload,
      };
    },
    hideSnackbar: state => {
      state.snackbarConfig = null;
    },
    showAlertDialog: (state, action) => {
      state.alertDialogConfig = {
        open: true,
        title: '',
        message: '',
        btnAnnullaText: 'Annulla',
        btnConfermaText: 'Conferma',
        ...action.payload,
      };
    },
    hideAlertDialog: state => {
      state.alertDialogConfig = null;
    },
  },
});

export const {
  toggleDrawer,
  toggleDrawerComunicazioni,
  showSnackbar,
  hideSnackbar,
  showAlertDialog,
  hideAlertDialog,
} = appSlice.actions;

export const appReducer = appSlice.reducer;
