import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, IconButton } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toggleDrawerComunicazioni } from '../redux/app/slice';
import { useEffect, useRef, useState } from 'react';
import { clearComunicazione } from '../redux/comunicazioni/slice';
import { leggiComunicazione } from '../redux/comunicazioni/api';

const giorniSettimana = [
  'Domenica',
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
];
const mesi = [
  'gennaio',
  'febbraio',
  'marzo',
  'aprile',
  'maggio',
  'giugno',
  'luglio',
  'agosto',
  'settembre',
  'ottobre',
  'novembre',
  'dicembre',
];

function ComunicazioniDrawer({ headerRef }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const drawerRef = useRef(null);
  const { drawerComunicazioniOpen } = useSelector(state => state.app);
  const { comunicazioni } = useSelector(state => state.comunicazioni);
  const [headerHeight, setHeaderHeight] = useState(0);

  const formatDate = date => {
    const today = new Date();
    const d = new Date(date);
    let f = `${giorniSettimana[d.getDay()]} ${d.getDate()} ${mesi[d.getMonth()]} ${d.getFullYear()}`;
    if (
      d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear()
    ) {
      f = `Oggi - ${f}`;
    }

    return f;
  };

  const onComunicazioneClick = comunicazione => {
    onLeggiComunicazione(comunicazione.id);
    dispatch(toggleDrawerComunicazioni());
    navigate('/comunicazioni', {
      state: { id: comunicazione.id },
    });
  };

  //group by created_at day
  const groupedComunicazioni = comunicazioni.reduce((acc, comunicazione) => {
    const date = formatDate(comunicazione.created_at);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(comunicazione);
    return acc;
  }, {});

  const onLeggiComunicazione = async id => {
    try {
      dispatch(clearComunicazione(id));
      await leggiComunicazione(id);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const _headerHeight = headerRef?.current?.clientHeight ?? 0;
    setHeaderHeight(_headerHeight);
  }, [headerRef]);

  useEffect(() => {
    document.addEventListener('mousedown', e => {
      if (drawerRef) {
        //se clicco outside drawer lo chiudo
        const drawerPos = drawerRef?.current?.getBoundingClientRect();
        if (!drawerPos) return;
        if (e.pageX < drawerPos.x || e.pageY < drawerPos.y) {
          dispatch(toggleDrawerComunicazioni());
        }
      }
    });

    return () => {
      document.removeEventListener('mousedown', () => {});
    };
  }, []);

  return (
    <Drawer
      variant={drawerComunicazioniOpen ? 'permanent' : 'temporary'}
      anchor="right"
      open={drawerComunicazioniOpen}
      onClose={() => {
        dispatch(toggleDrawerComunicazioni());
      }}
      sx={{
        flexShrink: 0,
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'secondary.main',
          minWidth: '200px',
          height: 'calc(100% - ' + headerHeight + 'px)',
          position: 'absolute',
          top: headerHeight,
        },
      }}
    >
      <Stack
        ref={drawerRef}
        sx={{
          width: '30dvw',
          height: '100%',
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography variant="h6" color="white">
            News e Comunicazioni
          </Typography>
        </Stack>

        {comunicazioni.length == 0 ? (
          <Stack sx={{ p: 2 }}>
            <Typography variant="body2" color="white">
              Nessuna news presente
            </Typography>
          </Stack>
        ) : (
          <></>
        )}

        {Object.keys(groupedComunicazioni).map(date => (
          <Stack key={date} sx={{ p: 2, pt: 0 }}>
            <Typography variant="subtitle1" color="white">
              {date}
            </Typography>
            <Stack>
              {groupedComunicazioni[date].map(comunicazione => (
                <Card key={comunicazione.id} sx={{ mb: 1 }}>
                  <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
                    <Box sx={{ color: 'white', width: 20, height: 20 }}></Box>

                    <Box sx={{ flexGrow: 1, ml: 2 }}>
                      <Typography
                        sx={{ mt: 1, mb: 2 }}
                        component="h2"
                        variant="h6"
                      >
                        {comunicazione.titolo.toUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        <span
                          style={{
                            maxHeight: '100px',
                            overflow: 'hidden',
                            display: '-webkit-box',
                            WebkitLineClamp: 5,
                            WebkitBoxOrient: 'vertical',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'normal',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: comunicazione.testo,
                          }}
                        ></span>
                      </Typography>
                    </Box>
                    <IconButton
                      sx={{ border: 'none' }}
                      size="small"
                      onClick={() => onComunicazioneClick(comunicazione)}
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Drawer>
  );
}

export default ComunicazioniDrawer;
