import { useEffect, useState } from 'react';
import { Card, IconButton, Stack, Typography } from '@mui/material';
import LoadingMask from '../../../components/LoadingMask';
import { getClientiReport } from '../../../redux/report/api';
import ActionErrorMessage from '../../../components/ActionErrorMessage';
import TotaleComponent from './TotaleComponent';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/it';
import GraficoBarreOrizzontale from './GraficoBarreOrizzontale';
import IntestazioneGrafici from './IntestazioneGrafici';
import GraficoBarre from './GraficoBarre';
import SelectClienti from './SelectClienti';
import { Delete } from '@mui/icons-material';

dayjs.locale('it');

export default function ClientiPanel() {
  const [dataInizio, setDataInizio] = useState(
    dayjs().subtract(1, 'month').startOf('month')
  );
  const [dataFine, setDataFine] = useState(
    dayjs().add(1, 'month').endOf('month')
  );
  const [filtroCliente, setFiltroCliente] = useState(null);
  const [clientiData, setClientiData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);

  const loadData = async (data_inizio, data_fine, id_azienda) => {
    setIsLoading(true);
    try {
      const response = await getClientiReport(
        data_inizio,
        data_fine,
        id_azienda
      );
      setClientiData(response);
    } catch (error) {
      setLoadingError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataInizio && dataFine) {
      if (dataInizio < dataFine) {
        loadData(
          dataInizio.format('YYYY-MM-DD'),
          dataFine.format('YYYY-MM-DD'),
          filtroCliente
        );
      }
    }
  }, [dataInizio, dataFine, filtroCliente]);

  return (
    <Stack sx={{ marginTop: 5, position: 'relative' }}>
      <Card sx={{ backgroundColor: '#FAFAFA' }}>
        <Typography component="h3" variant="h5" sx={{ color: '#284BAA' }}>
          Clienti
        </Typography>
        <Stack sx={{ marginTop: 3, marginBottom: 3 }} flexDirection="row">
          <Stack sx={{ minWidth: 300, marginRight: 3 }} flexDirection={'row'}>
            <SelectClienti
              placeholder="Seleziona Cliente"
              name="id_cliente"
              value={filtroCliente}
              onChange={event => setFiltroCliente(event.target.value)}
            />
            <IconButton
              color="primary"
              onClick={() => setFiltroCliente(null)}
              sx={{ marginLeft: 2, height: '100%' }}
            >
              <Delete />
            </IconButton>
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="it">
            <DatePicker
              label="Data Inizio"
              value={dataInizio}
              format="DD/MM/YYYY"
              onChange={val => setDataInizio(val)}
              sx={{ marginRight: 3 }}
            />
            <DatePicker
              label="Data Fine"
              value={dataFine}
              format="DD/MM/YYYY"
              onChange={val => setDataFine(val)}
            />
          </LocalizationProvider>
        </Stack>
        {clientiData && (
          <Stack>
            {clientiData.counter && (
              <Stack
                flexDirection="row"
                sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}
              >
                <TotaleComponent
                  titolo={'Numero Clienti Totali'}
                  sottotitolo={'Numero Totale di Clienti con Noleggi Attivi'}
                  value={clientiData.counter}
                />
                <Stack sx={{ flex: 1 }}></Stack>
              </Stack>
            )}
            {(clientiData.aziende || clientiData.importiAziende) && (
              <>
                <Stack
                  flexDirection="row"
                  sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}
                >
                  <IntestazioneGrafici
                    titolo={'N. Noleggi Attivi per Cliente'}
                  />
                  <IntestazioneGrafici titolo={'Tot. Importo per Cliente'} />
                </Stack>
                <Stack
                  flexDirection="row"
                  sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}
                >
                  <GraficoBarre value={clientiData.aziende} />
                  <GraficoBarreOrizzontale
                    value={clientiData.importiAziende}
                    format={true}
                    symbol={'\u00A0€'}
                  />
                </Stack>
              </>
            )}
            {(clientiData.importoAzienda || clientiData.noleggiAzienda) && (
              <>
                <Stack
                  flexDirection="row"
                  sx={{ flex: 1, marginTop: 2, marginBottom: 2 }}
                >
                  <TotaleComponent
                    titolo={'Numero Noleggi Attivi Cliente'}
                    value={clientiData.noleggiAzienda}
                  />
                  <TotaleComponent
                    titolo={'Importo Noleggi Attivi Clienti'}
                    value={clientiData.importoAzienda}
                    format={true}
                    symbol={'\u00A0€'}
                  />
                </Stack>
              </>
            )}
          </Stack>
        )}
      </Card>
      {loadingError != null && <ActionErrorMessage error={loadingError} />}
      {isLoading ? <LoadingMask /> : <></>}
    </Stack>
  );
}
