import { Stack } from '@mui/material';
import { PieChart } from '@mui/x-charts';
import { useEffect, useState } from 'react';
import IntestazioneGrafici from './IntestazioneGrafici';

export default function GraficoTorta({ titolo, sottotitolo, value, small }) {
  const [chartData, setChartData] = useState([]);
  const [pieHeight, setPieHeight] = useState(250);
  const [pieInnerRadius, setPieInnerRadius] = useState(80);

  const pieColor = [
    '#3476D5',
    '#493FD7',
    '#31BED5',
    '#25449C',
    /* '#25449C',
    '#25449C',
    '#25449C',
    '#25449C', */
  ];

  useEffect(() => {
    if (value) {
      setChartData(formatData(value));
    } else {
      setChartData([]);
    }
  }, [value]);

  useEffect(() => {
    if (small) {
      setPieHeight(200);
      setPieInnerRadius(60);
    }
  }, [small]);

  const formatData = data => {
    return Object.values(data).map(item => ({
      value: item.counter,
      label: item.nome,
    }));
  };

  return (
    <Stack sx={{ flex: 1 }} justifyContent={'center'}>
      <IntestazioneGrafici titolo={titolo} sottotitolo={sottotitolo} />
      {chartData && (
        <PieChart
          margin={{ top: 0, left: 0, right: 200 }}
          sx={{ mt: 2, height: pieHeight }}
          colors={pieColor}
          height={200}
          series={[
            {
              data: chartData,
              innerRadius: pieInnerRadius,
              cornerRadius: 5,
            },
          ]}
          slotProps={{
            legend: {},
          }}
        />
      )}
    </Stack>
  );
}
