import { configureStore } from '@reduxjs/toolkit';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './auth/slice';
import { noleggiReducer } from './noleggi/slice';
import { accountReducer } from './account/slice';
import { clientiReducer } from './clienti/slice';
import { notificheReducer } from './notifiche/slice';
import { comunicazioniReducer } from './comunicazioni/slice';
import { appReducer } from './app/slice';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token', 'user'],
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    noleggi: noleggiReducer,
    account: accountReducer,
    clienti: clientiReducer,
    notifiche: notificheReducer,
    comunicazioni: comunicazioniReducer,
    app: appReducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
