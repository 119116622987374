import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import ImportoCanone from './ImportoCanone';
import CalcoloRataSvg from '../../../assets/img/CalcoloRata.svg';
import { formatCurrency } from '../../../utils/formatNumber';

function InfoIntestazioneNoleggio({ noleggio, dettagli }) {
  let importo = 0;
  if (dettagli.length > 0) {
    importo = dettagli.reduce((acc, dettaglio) => {
      return acc + dettaglio.importo * dettaglio.quantita;
    }, 0);
  } else {
    importo = noleggio?.importo;
  }

  return (
    <Card sx={{ backgroundColor: 'background.primaryLight', border: 'none' }}>
      <CardContent>
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Card
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 10,
              height: 10,
              bgcolor: 'background.darkest',
              color: 'white',
              zIndex: 1,
              top: '30px',
            }}
          >
            <img src={CalcoloRataSvg} alt="Calcolo Rata" />
          </Card>

          <Stack
            flexDirection={'row'}
            justifyContent={'space-between'}
            sx={{ flexGrow: 1 }}
            alignItems={'center'}
          >
            <Typography variant="h6" sx={{ ml: 4 }}>
              Rata Noleggio
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h6">
                Importo: {formatCurrency(importo)}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                sx={{
                  mr: 2,
                  alignSelf: 'start',
                  fontFamily: 'Poppins-SemiBold',
                }}
              >
                Canone Rata (Mensile):
              </Typography>

              <ImportoCanone
                importo={importo}
                mesi={noleggio?.durata_mesi}
                assicurazione={noleggio?.flg_assicurazione}
                dettagli={dettagli}
                backgroundColor="transparent"
                compactMode={true}
              />
            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
}

export default InfoIntestazioneNoleggio;
