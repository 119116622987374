import {
  Box,
  Button,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  completaRichiesta,
  confermaRichiesta,
  getPdfProposta,
  salvaRichiesta,
} from '../../../redux/noleggi/api';
import LoadingMask from '../../../components/LoadingMask';
import IntestazioneNoleggio from './IntestazioneNoleggio';
import CardInserimentoStep from './CardInserimentoStep';
import InfoIntestazioneNoleggio from './InfoIntestazioneNoleggio';
import CaricaFile from './CaricaFile';
import ConfiguraBeni from './ConfiguraBeni';
import { Close, Edit, Print, Send } from '@mui/icons-material';
import useResizer from '../../../hooks/useResizer';
import InserisciDatiCliente from './InserisciDatiCliente';
import useInserisciDatiCliente from '../hook/useInserisciDatiCliente';
import FirmaDocumento from './FirmaDocumento';
import { useDispatch } from 'react-redux';
import { showAlertDialog, showSnackbar } from '../../../redux/app/slice';
import { downloadBlob } from '../../../utils/download';
import IntestazioneNoleggioVerticale from './IntestazioneNoleggioVerticale';
import ImportoCanoneMobile from './ImportoCanoneMobile';

export default function DefinizioneProposta({
  writeMode,
  noleggio,
  setNoleggio,
  setViewMode,
}) {
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));
  const matchLg = useMediaQuery(theme.breakpoints.up('lg'));
  const mainContentRef = useRef(null);
  useResizer(mainContentRef);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const useInserisciDatiClienteHook = useInserisciDatiCliente(noleggio.azienda);
  const [filePreventivo, setFilePreventivo] = useState(null);

  const buildFormData = datiClienteValidationResult => {
    const formData = new FormData();
    if (noleggio.id != null) {
      formData.append('id', noleggio.id);
    }
    formData.append('id_stato', noleggio.id_stato);
    formData.append('id_step', noleggio.id_step);
    formData.append('durata_mesi', noleggio.durata_mesi);
    formData.append('importo', noleggio.importo);
    formData.append('flg_assicurazione', noleggio.flg_assicurazione);
    formData.append('flg_sdd', noleggio.flg_sdd);

    if (datiClienteValidationResult == 1) {
      const clienteData = useInserisciDatiClienteHook.formData;
      formData.append('azienda', JSON.stringify(clienteData));
    }

    if (filePreventivo != null) {
      formData.append('preventivo', filePreventivo);
    }

    if (noleggio.dettagli != null) {
      formData.append('dettagli', JSON.stringify(noleggio.dettagli));
    }

    return formData;
  };

  const onNoleggioLoaded = noleggio => {
    setNoleggio(noleggio);
    useInserisciDatiClienteHook.setFormData(noleggio.azienda);
  };

  const onCompletaRichiesta = async () => {
    setIsLoading(true);
    try {
      const result = await completaRichiesta(noleggio);
      onNoleggioLoaded(result);
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onSalvaRichiesta = async (explicit = true) => {
    //valido il form dei dati del cliente
    const datiClienteValidationResult =
      useInserisciDatiClienteHook.handleSubmit();
    if (datiClienteValidationResult == -1) {
      dispatch(
        dispatch(
          showSnackbar({
            message: 'Devi inserire i dati del cliente per inviare la proposta',
            severity: 'error',
          })
        )
      );
      return;
    }

    if (explicit) {
      if (noleggio.id_stato == 'IB') {
        dispatch(
          showAlertDialog({
            btnConfermaText: 'SALVA PROPOSTA',
            btnAnnullaText: 'ANNULLA',
            title: 'Salva Proposta',
            message: 'Vuoi salvare la proposta di noleggio?',
            onConfirm: () => {
              confirmSalvaRichiesta(datiClienteValidationResult, explicit);
            },
          })
        );
        return;
      }
    }

    return confirmSalvaRichiesta(datiClienteValidationResult, explicit);
  };

  const confirmSalvaRichiesta = async (
    datiClienteValidationResult,
    explicit = true
  ) => {
    setIsLoading(true);
    try {
      const result = await salvaRichiesta(
        buildFormData(datiClienteValidationResult)
      );
      onNoleggioLoaded(result);

      if (explicit) {
        dispatch(
          showSnackbar({
            message: 'Proposta di noleggio salvata con successo',
            severity: 'success',
          })
        );
      }

      return result;
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onScaricaProposta = async id => {
    setIsLoading(true);
    try {
      const pdfData = await getPdfProposta(id);
      downloadBlob(pdfData, 'application/pdf', 'PropostaNoleggio.pdf');
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    navigate(-1);
  };

  const onInviaProposta = () => {
    //valido il form dei dati del cliente
    const datiClienteValidationResult =
      useInserisciDatiClienteHook.handleSubmit(true);
    if (datiClienteValidationResult == -1) {
      dispatch(
        showSnackbar({
          message: 'Devi inserire i dati del cliente per inviare la proposta',
          severity: 'error',
        })
      );
      return;
    }

    if (!noleggio.flg_preventivo && filePreventivo == null) {
      dispatch(
        showSnackbar({
          message: 'Devi caricare il preventivo per inviare la proposta',
          severity: 'error',
        })
      );
      return;
    }

    if ((noleggio.dettagli ?? []).length == 0) {
      dispatch(
        showSnackbar({
          message: 'Devi configurare i beni e servizi per inviare la proposta',
          severity: 'error',
        })
      );
      return;
    }

    dispatch(
      showAlertDialog({
        btnConfermaText: 'INOLTRA PROPOSTA DI NOLEGGIO',
        btnAnnullaText: 'ANNULLA',
        title: 'Sei sicuro di voler inoltrare la Proposta di Noleggio?',
        message:
          'Per essere attivata, la proposta di Noleggio dovrà essere valutata positiamente da Doinn Rental',
        onConfirm: () => {
          confirmInviaProposta(datiClienteValidationResult);
        },
      })
    );
  };

  const confirmInviaProposta = async datiClienteValidationResult => {
    setIsLoading(true);
    try {
      const result = await confermaRichiesta(
        buildFormData(datiClienteValidationResult)
      );
      onNoleggioLoaded(result);

      dispatch(
        showAlertDialog({
          btnConfermaText: 'OK',
          btnAnnullaText: null,
          title: 'La Pratica di Proposta Noleggio è stata inoltrata',
          message:
            'La Pratica di Proposta Noleggio è stata inoltrata al Noleggiatore. Segui lo status della valutazione nella sezione "Pratiche Inoltrate"',
        })
      );

      setViewMode('read');

      return result;
    } catch (error) {
      dispatch(showSnackbar({ message: error, severity: 'error' }));
    } finally {
      setIsLoading(false);
    }
  };

  const onVisualizzaRiepilogo = async () => {
    const result = await onSalvaRichiesta(false);
    if (result != null) {
      onScaricaProposta(result.id);
    }
  };

  const styleHiddenStack = { mt: 4, opacity: '0.2', pointerEvents: 'none' };
  const styleVisibleStack = { mt: 4 };
  const styleStack =
    noleggio.id_stato != 'DC' ? styleHiddenStack : styleVisibleStack;

  return (
    <>
      {isLoading && <LoadingMask />}
      <Stack sx={{ pt: 3, pl: 3, pr: 3 }} ref={mainContentRef}>
        {matchMd ? (
          <Box>
            <Stack flexDirection="row" justifyContent="space-between">
              <Typography component="h2" variant="h4" sx={{ mb: 2 }}>
                {writeMode == 'new'
                  ? 'Nuova Proposta di Noleggio'
                  : writeMode == 'clone'
                    ? 'Clona Proposta di Noleggio'
                    : 'Proposta di Noleggio '}
              </Typography>
              <Stack flexDirection="row">
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ mr: 2 }}
                  size="small"
                  onClick={onSalvaRichiesta}
                  startIcon={<Edit />}
                >
                  SALVA PROPOSTA
                  {noleggio.id_stato == 'IB' && ' IN BOZZA'}
                </Button>
                {noleggio.id_stato == 'DC' && (
                  <Button
                    variant="contained"
                    sx={{ mr: 2 }}
                    size="small"
                    onClick={onInviaProposta}
                    startIcon={<Send />}
                  >
                    INOLTRA PROPOSTA DI NOLEGGIO
                  </Button>
                )}

                <IconButton
                  size="small"
                  onClick={onVisualizzaRiepilogo}
                  variant="contained"
                  color="primary"
                  sx={{ mr: 2 }}
                >
                  <Print />
                </IconButton>

                <IconButton size="small" onClick={onClose}>
                  <Close />
                </IconButton>
              </Stack>
            </Stack>

            {noleggio.id_stato == 'DC' && (
              <Box sx={{ mb: 2, px: 1.5 }}>
                <InfoIntestazioneNoleggio
                  noleggio={noleggio}
                  dettagli={noleggio.dettagli ?? []}
                />
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <Stack
              flexDirection="row"
              justifyContent="end"
              alignItems={'end'}
              gap={1}
              sx={{ mb: 2 }}
            >
              {noleggio?.id_stato != 'IB' && (
                <Button
                  variant="contained"
                  color="white"
                  sx={{ width: '200px' }}
                  size="small"
                  onClick={onSalvaRichiesta}
                  startIcon={<Edit />}
                >
                  SALVA PROPOSTA
                  {noleggio.id_stato == 'IB' && ' IN BOZZA'}
                </Button>
              )}

              {noleggio.id_stato == 'DC' && (
                <Button
                  variant="contained"
                  size="small"
                  sx={{ width: '200px' }}
                  onClick={onInviaProposta}
                  startIcon={<Send />}
                >
                  INOLTRA
                </Button>
              )}

              <IconButton
                size="small"
                onClick={onVisualizzaRiepilogo}
                variant="contained"
                color="primary"
              >
                <Print />
              </IconButton>

              <IconButton size="small" onClick={onClose}>
                <Close />
              </IconButton>
            </Stack>

            {noleggio.id_stato == 'DC' && (
              <Box sx={{ mb: 2 }}>
                <ImportoCanoneMobile
                  importo={noleggio.importo}
                  dettagli={noleggio.dettagli ?? []}
                  mesi={noleggio?.durata_mesi}
                  assicurazione={noleggio?.flg_assicurazione}
                  backgroundColor="white"
                  compactMode={false}
                />
              </Box>
            )}
          </Box>
        )}

        <Stack sx={{ flexGrow: 1, overflowY: 'auto' }}>
          <Box>
            <Box>
              {matchLg ? (
                <IntestazioneNoleggio
                  noleggio={noleggio}
                  setNoleggio={setNoleggio}
                  compactViewMode={noleggio.id_stato == 'DC'}
                  onSalvaRichiesta={onSalvaRichiesta}
                  onScaricaProposta={onScaricaProposta}
                />
              ) : (
                <IntestazioneNoleggioVerticale
                  noleggio={noleggio}
                  setNoleggio={setNoleggio}
                  compactViewMode={noleggio.id_stato == 'DC'}
                  importoRow={matchMd ? true : false}
                />
              )}
            </Box>

            {noleggio.id_stato != 'DC' ? (
              <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                  <Typography component="h4" variant="h6">
                    Vuoi completare la Proposta di Noleggio?
                  </Typography>
                  <Typography variant="body1">
                    Per inoltrare una proposta di noleggio devi inserire i dati
                    richiesti
                  </Typography>
                  {matchMd ? (
                    <Button
                      variant="contained"
                      sx={{ mt: 2 }}
                      onClick={onCompletaRichiesta}
                    >
                      COMPLETA PROPOSTA DI NOLEGGIO
                    </Button>
                  ) : (
                    <Stack alignItems={'center'}>
                      <Button
                        variant="contained"
                        color="white"
                        sx={{ mt: 2, width: '300px' }}
                        size="small"
                        onClick={onSalvaRichiesta}
                        startIcon={<Edit />}
                      >
                        SALVA PROPOSTA
                        {noleggio.id_stato == 'IB' && ' IN BOZZA'}
                      </Button>
                      <Button
                        variant="contained"
                        sx={{ mt: 2, width: '300px' }}
                        onClick={onCompletaRichiesta}
                      >
                        COMPLETA PROPOSTA DI NOLEGGIO
                      </Button>
                    </Stack>
                  )}
                </Box>
              </Stack>
            ) : (
              <></>
            )}

            <Stack sx={styleStack} spacing={4}>
              <CardInserimentoStep
                number={1}
                title="Inserisci Dati Cliente"
                subtitle="Puoi salvare l'operazione anche senza inserire i dettagli del cliente"
                component={
                  <InserisciDatiCliente
                    hook={useInserisciDatiClienteHook}
                    noleggio={noleggio}
                    setNoleggio={setNoleggio}
                  />
                }
              />
              <CardInserimentoStep
                number={2}
                title="Carica Preventivo"
                subtitle="Puoi salvare l'operazione anche senza inserire i dettagli del cliente"
                component={
                  <Grid container justifyContent={'center'} spacing={2}>
                    <Grid size={{ xs: 12, sm: 8 }}>
                      <CaricaFile
                        alreadyPresentMessage="Hai già caricato un preventivo. Per sovrascriverlo, carica un
                nuovo file."
                        isPresent={noleggio.flg_preventivo}
                        onLoad={file => setFilePreventivo(file)}
                        onCancel={() => setFilePreventivo(null)}
                      />
                    </Grid>
                  </Grid>
                }
              />
              <CardInserimentoStep
                number={3}
                title="Configura Beni e Servizi"
                subtitle="Censisci i beni e servizi richiesti al noleggio"
                component={
                  <ConfiguraBeni
                    beni={noleggio.dettagli ?? []}
                    setBeni={beni => {
                      setNoleggio({ ...noleggio, dettagli: beni });
                    }}
                  />
                }
              />
              <CardInserimentoStep
                number={4}
                title="Firma Documento"
                subtitle="Inserisci la modalità di firma scelta dal Cliente Finale (è possibile modificarla in seguito)"
                component={<FirmaDocumento />}
              />
            </Stack>
          </Box>
        </Stack>
      </Stack>
    </>
  );
}
