import {
  Box,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ArrowForwardIos, InfoOutlined } from '@mui/icons-material';
import { formatCurrency } from '../../../utils/formatNumber';
import { formatStringDate } from '../../../utils/formadDate';

const stati = {
  AM: 'Avanzato Manualmente',
  DC: 'Da completare',
  DS: 'Da Sbloccare',
  FD: 'Firma Documenti',
  IC: 'In Corso',
  KO: 'In Errore',
  RC: 'Rifiutata dal Cliente',
  RF: 'Rifiutata',
  RP: 'Ripetuto',
  SC: 'Scaduta',
  TE: 'Terminato',
  VD: 'Da validare documenti',
  CF: 'Confermata',
};

const steps = {
  1: 'Compilazione Dati',
  2: 'Controllo Automatico Dati Azienda',
  3: 'Controllo Automatico Dati Fornitore',
  4: 'Conferma Dati Inseriti',
  5: 'Creazione Contratto',
  6: 'Notifica Firma Documenti',
  7: 'Firma Documenti Cliente',
  8: 'Ricezione e Validazione Documenti Firmati',
  9: 'Invio Documenti',
  10: 'Richiesta Terminata',
};
//in perfezionamento: 4,5,6 con stato IC
//in attesa: step 7 con stato FD
//pratica confermata: stato cf
//materiale consegnato: TE
export default ({ onEdit }) => {
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  let columns = [
    {
      field: 'codice',
      headerName: 'Codice',
      renderCell: params => {
        return (
          <Stack
            flexDirection="row"
            sx={{
              justifyContent: 'start',
              height: '100%',
              alignItems: 'center',
            }}
          >
            {params.row.flg_scadenza && (
              <InfoOutlined
                sx={{ color: 'primary.main', width: 20, height: 20, mr: 1 }}
              />
            )}
            {params.value}
          </Stack>
        );
      },
    },
    {
      field: 'cliente',
      headerName: 'Cliente',
      flex: 1,
      renderCell: params => {
        return params.row.azienda != null ? (
          <Stack sx={{ justifyContent: 'center', height: '100%' }}>
            <Typography sx={{ fontFamily: 'Poppins-SemiBold' }} color="primary">
              {params.row.azienda.ragione_sociale}
            </Typography>
          </Stack>
        ) : (
          '-'
        );
      },
    },
    {
      field: 'importo',
      headerName: 'Importo',
      flex: 1,
      valueFormatter: value => {
        return formatCurrency(value);
      },
    },
    {
      field: 'durata_mesi',
      headerName: 'Durata (mesi)',
      flex: 0.7,
    },
    {
      field: 'created_at',
      headerName: 'Data Creazione',
      flex: 1,
      valueFormatter: value => {
        return formatStringDate(value);
      },
    },
    {
      field: 'id_stato',
      headerName: 'Stato',
      flex: 1,
      renderCell: params => {
        if (params.value === 'IB') {
          return (
            <Stack sx={{ justifyContent: 'center', height: '100%' }}>
              <Typography variant="body2">In Bozza</Typography>
              <Typography
                variant="caption"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                Scade il {formatStringDate(params.row.data_scadenza)}
              </Typography>
            </Stack>
          );
        } else if (params.value === 'IC') {
          return (
            <Stack sx={{ justifyContent: 'center', height: '100%' }}>
              <Typography variant="body2">In Corso</Typography>
              <Typography
                variant="caption"
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {steps[params.row.id_step]}
              </Typography>
            </Stack>
          );
        } else if (params.value === 'TE') {
          return (
            <Stack sx={{ justifyContent: 'center', height: '100%' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', px: 1 }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                  <LinearProgress
                    variant="determinate"
                    value={params.row.percentuale_avanzamento}
                  />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {`${Math.round(params.row.percentuale_avanzamento)}%`}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          );
        } else {
          return (
            <Stack sx={{ justifyContent: 'center', height: '100%' }}>
              <Typography variant="body2">{stati[params.value]}</Typography>
            </Stack>
          );
        }
      },
    },
    {
      field: 'utente',
      headerName: 'Utente',
      flex: 1,
    },
    {
      field: 'id',
      headerName: '',
      filterable: false,
      width: 40,
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          sx={{
            justifyContent: 'start',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={() => onEdit(params.value)}
          >
            <ArrowForwardIos />
          </IconButton>
        </Stack>
      ),
    },
  ];

  if (!matchMd) {
    //rimuovo la colonna con l'utente
    columns = columns.filter(
      column => column.field !== 'utente' && column.field !== 'created_at'
    );
  }

  return columns;
};
