import { createSlice } from '@reduxjs/toolkit';
import {
  getNoleggi,
  getListaCategorieProdotti,
  getDocumenti,
  getListaRichiesteModifica,
} from './operations';
import {
  createAsyncState,
  handlePending,
  handleFulfilled,
  handleRejected,
} from '../base';

const initialState = {
  documenti: [],
  noleggi: [],
  categorieProdotti: [],
  richiesteModifica: [],
  noleggioClone: null,
  getRichiesteModificaAction: createAsyncState(),
  getDocumentiAction: createAsyncState(),
  getNoleggiAction: createAsyncState(),
  getListaCategorieProdottiAction: createAsyncState(),
};

const noleggiSlice = createSlice({
  name: 'noleggi',
  initialState,
  reducers: {
    setNoleggioClone(state, action) {
      state.noleggioClone = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getNoleggi.pending, state => {
        handlePending(state.getNoleggiAction);
      })
      .addCase(getNoleggi.fulfilled, (state, action) => {
        state.noleggi = action.payload;
        handleFulfilled(state.getNoleggiAction, action);
      })
      .addCase(getNoleggi.rejected, (state, action) => {
        handleRejected(state.getNoleggiAction, action);
      })
      .addCase(getListaCategorieProdotti.pending, state => {
        handlePending(state.getListaCategorieProdottiAction);
      })
      .addCase(getListaCategorieProdotti.fulfilled, (state, action) => {
        state.categorieProdotti = action.payload;
        handleFulfilled(state.getListaCategorieProdottiAction, action);
      })
      .addCase(getListaCategorieProdotti.rejected, (state, action) => {
        handleRejected(state.getListaCategorieProdottiAction, action);
      })
      .addCase(getDocumenti.pending, state => {
        handlePending(state.getDocumentiAction);
      })
      .addCase(getDocumenti.fulfilled, (state, action) => {
        state.documenti = action.payload;
        handleFulfilled(state.getDocumentiAction, action);
      })
      .addCase(getDocumenti.rejected, (state, action) => {
        handleRejected(state.getDocumentiAction, action);
      })
      .addCase(getListaRichiesteModifica.pending, state => {
        handlePending(state.getRichiesteModificaAction);
      })
      .addCase(getListaRichiesteModifica.fulfilled, (state, action) => {
        state.richiesteModifica = action.payload;
        handleFulfilled(state.getRichiesteModificaAction, action);
      })
      .addCase(getListaRichiesteModifica.rejected, (state, action) => {
        handleRejected(state.getRichiesteModificaAction, action);
      });
  },
});

export const { setNoleggioClone } = noleggiSlice.actions;

export const noleggiReducer = noleggiSlice.reducer;
