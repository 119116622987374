import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';

import useAuth from '../hooks/useAuth';
import { useDispatch } from 'react-redux';
import { logout } from '../redux/auth/slice';
import { useNavigate } from 'react-router-dom';
import SideMenuContent from './SideMenuContent';

function SideMenuMobile({ open, toggleDrawer }) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logout());
  };

  const onNavigate = (path, data) => {
    toggleDrawer(false)();
    navigate(path, data);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={toggleDrawer(false)}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          backgroundImage: 'none',
          backgroundColor: 'background.paper',
          minWidth: '200px',
        },
      }}
    >
      <Stack
        sx={{
          maxWidth: '70dvw',
          height: '100%',
        }}
      >
        <Stack direction="row" sx={{ p: 2, pb: 0, gap: 1 }}>
          <Stack
            direction="row"
            sx={{ gap: 1, alignItems: 'center', flexGrow: 1, p: 1 }}
          >
            <Avatar
              sizes="small"
              alt={user.username}
              sx={{ width: 24, height: 24 }}
            />
            <Typography component="p" variant="caption">
              {user.email}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
        <Stack sx={{ flexGrow: 1, m: 2 }}>
          <SideMenuContent onNavigate={onNavigate} />
        </Stack>
        <Divider />
        <Stack sx={{ p: 2 }}>
          <Button
            onClick={onLogout}
            variant="outlined"
            fullWidth
            startIcon={<LogoutRoundedIcon />}
          >
            Logout
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
}

SideMenuMobile.propTypes = {
  open: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
};

export default SideMenuMobile;
