import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getNoleggio } from '../../redux/noleggi/api';
import ActionErrorMessage from '../../components/ActionErrorMessage';
import LoadingMask from '../../components/LoadingMask';
import DefinizioneProposta from './components/DefinizioneProposta';
import PraticaNoleggio from './components/PraticaNoleggio';
import { useSelector } from 'react-redux';

export default function Noleggio() {
  const location = useLocation();
  const { noleggioClone } = useSelector(state => state.noleggi);
  const [noleggioData, setNoleggioData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingError, setLoadingError] = useState(null);
  const [viewMode, setViewMode] = useState('read');
  const [writeMode, setWriteMode] = useState('new');
  const [key, setKey] = useState(0);

  const onPageLoad = async () => {
    setIsLoading(true);
    if (
      location.pathname.includes('new') ||
      location.pathname.includes('completa')
    ) {
      if (location.pathname.includes('completa')) {
        setNoleggioData({
          id: null,
          id_stato: 'IB',
          id_step: null,
          durata_mesi: location.state.durata_mesi,
          importo: location.state.importo,
          flg_assicurazione: false,
        });
      } else {
        setNoleggioData({
          id: null,
          id_stato: 'IB',
          id_step: null,
          durata_mesi: 24,
          importo: 0,
          flg_assicurazione: false,
        });
      }

      setViewMode('edit');
      setWriteMode('new');
      setIsLoading(false);
    } else if (location.pathname.includes('clone')) {
      setNoleggioData({
        ...noleggioClone,
        id: null,
        id_stato: 'DC',
        id_step: null,
        flg_preventivo: false,
        interventi: [],
        documenti: [],
      });
      setViewMode('edit');
      setWriteMode('clone');
      setIsLoading(false);
    } else if (location.pathname.includes('edit')) {
      const state = location.state;
      setWriteMode('edit');
      try {
        const result = await getNoleggio(state.id);
        setNoleggioData(result);
      } catch (error) {
        setLoadingError(error);
      } finally {
        setIsLoading(false);
      }
    }
    setKey(prev => prev + 1);
  };

  useEffect(() => {
    onPageLoad();
  }, [location.pathname]);

  return (
    <Stack key={key}>
      {isLoading && <LoadingMask />}
      {loadingError != null && <ActionErrorMessage error={loadingError} />}
      {noleggioData != null ? (
        viewMode == 'read' ? (
          <PraticaNoleggio
            noleggioData={noleggioData}
            setViewMode={setViewMode}
          />
        ) : (
          <DefinizioneProposta
            writeMode={writeMode}
            noleggio={noleggioData}
            setNoleggio={setNoleggioData}
            setViewMode={setViewMode}
          />
        )
      ) : (
        <></>
      )}
    </Stack>
  );
}
