import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useAuth from '../hooks/useAuth';
import { ReactComponent as OpAttiveSvg } from '../assets/img/OpAttive.svg';
import { ReactComponent as OpNonAttiveSvg } from '../assets/img/OpNonAttive.svg';
import { ReactComponent as RichiestaModificaMenuSvg } from '../assets/img/RichiestaModificaMenu.svg';

import {
  Button,
  Card,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import {
  AddCircle,
  ArrowForwardIos,
  Dashboard,
  Description,
  Feed,
  People,
  PeopleAlt,
  PieChart,
} from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

export default function SideMenuContent({ onNavigate }) {
  const { user } = useAuth();
  const location = useLocation();
  // const theme = useTheme();
  // var menuColor = theme.palette.primary.main;
  var menuColor = '#274BAA';

  const styleCardNoActive = {
    p: 0,
    pt: 1,
    border: 'none',
    backgroundColor: 'transparent',
  };
  const styleCardActive = {
    pl: 0,
    pr: 1,
    py: 1,
    border: 'none',
    backgroundColor: 'background.primaryLight',
  };

  const itemStyle = {
    color: '#274BAA !important',
  };

  return (
    <Stack sx={{ flexGrow: 1 }}>
      <Button
        startIcon={<AddCircle />}
        variant="contained"
        onClick={() => onNavigate('/noleggi/noleggio/new', { replace: true })}
        sx={{ pt: 3, pb: 3 }}
      >
        <Typography
          variant="body2"
          sx={{ fontWeight: 500, lineHeight: '16px', textAlign: 'left' }}
        >
          <span>
            Nuova
            <br />
            Proposta Noleggio
          </span>
        </Typography>
      </Button>

      <ListItem disablePadding sx={{ display: 'block', mt: 2 }}>
        <ListItemButton
          selected={location.pathname === '/dashboard'}
          onClick={() => onNavigate('/dashboard')}
        >
          <ListItemIcon>
            <Dashboard sx={itemStyle} />
          </ListItemIcon>
          <ListItemText sx={itemStyle} primary="Dashboard" />
        </ListItemButton>
      </ListItem>

      <Divider sx={{ my: 2 }} />

      <Card
        sx={
          location.pathname.includes('/noleggi/attivi')
            ? styleCardActive
            : styleCardNoActive
        }
      >
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton onClick={() => onNavigate('/noleggi/attivi')}>
            <ListItemIcon>
              <OpAttiveSvg
                width={16}
                height={16}
                style={{ color: menuColor, padding: '1.33px' }}
              />
            </ListItemIcon>
            <ListItemText sx={itemStyle} primary="Operazioni Attive" />
          </ListItemButton>
        </ListItem>

        {location.pathname.includes('/noleggi/attivi') && (
          <List dense sx={{ p: 0, m: 0, ml: 3 }}>
            {[
              { name: 'Proposte In Bozza', path: '/noleggi/attivi/bozza' },
              {
                name: 'Pratiche Inoltrate',
                path: '/noleggi/attivi/inoltrate',
              },
              {
                name: 'Pratiche Confermate',
                path: '/noleggi/attivi/confermate',
              },
              { name: 'Noleggi Attivi', path: '/noleggi/attivi' },
            ].map((link, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  key={index}
                  selected={location.pathname === link.path}
                  onClick={() => onNavigate(link.path)}
                >
                  <ListItemText
                    secondary={link.name}
                    sx={{
                      ...itemStyle,
                      '& p': {
                        fontWeight:
                          location.pathname === link.path
                            ? 'bold !important'
                            : '',
                      },
                    }}
                  />
                  <ListItemIcon>
                    <ArrowForwardIos sx={itemStyle} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <Card
        sx={
          location.pathname.includes('/noleggi/non-attivi')
            ? styleCardActive
            : styleCardNoActive
        }
      >
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton onClick={() => onNavigate('/noleggi/non-attivi')}>
            <ListItemIcon>
              <OpNonAttiveSvg
                width={16}
                height={16}
                style={{ color: menuColor, padding: '1.33px' }}
              />
            </ListItemIcon>
            <ListItemText sx={itemStyle} primary="Operazioni Non Attive" />
          </ListItemButton>
        </ListItem>

        {location.pathname.includes('/noleggi/non-attivi') && (
          <List dense sx={{ p: 0, m: 0, ml: 3 }}>
            {[
              {
                name: 'Proposte Scadute',
                path: '/noleggi/non-attivi',
              },
              {
                name: 'Pratiche Declinate',
                path: '/noleggi/non-attivi/declinate',
              },
              {
                name: 'Noleggi Scaduti',
                path: '/noleggi/non-attivi/noleggi-scaduti',
              },
            ].map((link, index) => (
              <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  key={index}
                  selected={location.pathname === link.path}
                  onClick={() => onNavigate(link.path)}
                >
                  <ListItemText
                    secondary={link.name}
                    sx={{
                      ...itemStyle,
                      '& p': {
                        fontWeight:
                          location.pathname === link.path
                            ? 'bold !important'
                            : '',
                      },
                    }}
                  />
                  <ListItemIcon>
                    <ArrowForwardIos sx={itemStyle} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
      </Card>

      <ListItem disablePadding sx={{ display: 'block', mt: 1 }}>
        <ListItemButton
          selected={location.pathname === '/richieste-modifica'}
          onClick={() => onNavigate('/richieste-modifica')}
        >
          <ListItemIcon>
            <RichiestaModificaMenuSvg
              width={16}
              height={16}
              style={{ color: menuColor, padding: '1.33px' }}
            />
          </ListItemIcon>
          <ListItemText sx={itemStyle} primary="Richieste di Modifica" />
        </ListItemButton>
      </ListItem>

      <Divider sx={{ my: 2 }} />

      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          selected={location.pathname === '/clienti'}
          onClick={() => onNavigate('/clienti')}
        >
          <ListItemIcon>
            <PeopleAlt sx={itemStyle} />
          </ListItemIcon>
          <ListItemText sx={itemStyle} primary="Clienti" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          selected={location.pathname === '/documenti-fatture'}
          onClick={() => onNavigate('/documenti-fatture')}
        >
          <ListItemIcon>
            <Description sx={itemStyle} />
          </ListItemIcon>
          <ListItemText sx={itemStyle} primary="Documenti e Fatture" />
        </ListItemButton>
      </ListItem>

      <Divider sx={{ my: 2 }} />

      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          selected={location.pathname === '/report'}
          onClick={() => onNavigate('/report')}
        >
          <ListItemIcon>
            <PieChart sx={itemStyle} />
          </ListItemIcon>
          <ListItemText sx={itemStyle} primary="Report" />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          selected={location.pathname === '/comunicazioni'}
          onClick={() => onNavigate('/comunicazioni')}
        >
          <ListItemIcon>
            <Feed sx={itemStyle} />
          </ListItemIcon>
          <ListItemText sx={itemStyle} primary="News e Comunicazioni" />
        </ListItemButton>
      </ListItem>

      <Box sx={{ flexGrow: 1 }} />
      {user?.flg_admin == 1 && (
        <ListItem disablePadding sx={{ display: 'block', mb: 1 }}>
          <ListItemButton
            selected={location.pathname === '/gestione-account'}
            onClick={() => {
              onNavigate('/gestione-account');
            }}
          >
            <ListItemIcon>
              <People sx={itemStyle} />
            </ListItemIcon>
            <ListItemText sx={itemStyle} primary="Gestione Account" />
          </ListItemButton>
        </ListItem>
      )}
    </Stack>
  );
}
