import { Box, IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { Delete, Edit } from '@mui/icons-material';
import DecimalInputDecorated from '../../../components/DecimalInputDecorated';
import { formatCurrency } from '../../../utils/formatNumber';

export default function BeniModificaTable({
  rows,
  onQtaChange,
  onEdit,
  onDelete,
  viewMode = 'full',
  readOnly = false,
  showHeader = true,
}) {
  const columns = [
    { field: 'produttore', headerName: 'Produttore', flex: 1 },
    {
      field: 'categoria_prodotto_descrizione',
      headerName: 'Info',
      flex: 1.5,
      renderCell: params => {
        return (
          <Stack sx={{ justifyContent: 'center', height: '100%' }}>
            <Typography variant="body2">
              Categoria Prodotto: {params.row.categoria_prodotto_descrizione}
            </Typography>
            <Typography variant="body2">
              Codice Prodotto: {params.row.codice_prodotto}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'quantita_attuale',
      headerName: 'N. Beni Attuale',
      flex: 1,
      renderCell: params => {
        return (
          <Stack
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Typography variant="body2">
              {params.row.quantita_attuale}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'quantita',
      headerName: 'Nuova Quantità',
      flex: readOnly ? 0.5 : 1,
      renderCell: params => {
        return (
          <Stack sx={{ justifyContent: 'center', height: '100%' }}>
            {readOnly ? (
              <Typography variant="body2">{params.value}</Typography>
            ) : (
              <Box sx={{ maxWidth: '170px' }}>
                <DecimalInputDecorated
                  format={'decimal'}
                  step={1}
                  round={1}
                  value={params.value}
                  max={params.row.max ?? 99999}
                  min={params.row.min ?? 0}
                  onChange={value => onQtaChange(params.row.id, value)}
                  style={{ justifyContent: 'start' }}
                  readOnly={viewMode === 'richiestaModifica'}
                />
              </Box>
            )}
          </Stack>
        );
      },
    },
    {
      field: 'importo',
      headerName: 'Importo',
      flex: 1,
      valueFormatter: value => formatCurrency(value),
    },
  ];

  if (!readOnly && viewMode === 'full') {
    columns.push({
      field: 'id',
      headerName: 'Azioni',
      flex: 0.5,
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            color="primary"
            onClick={() => onEdit(params.row)}
          >
            <Edit />
          </IconButton>
          <IconButton
            size="small"
            color="primary"
            onClick={() => onDelete(params.row.id)}
          >
            <Delete />
          </IconButton>
        </Stack>
      ),
    });
  } else {
    columns[columns.length - 1].headerClassName = 'no-separator';
  }

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ xs: 12, md: 12, lg: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 100 } },
          }}
          autoHeight
          disableColumnMenu
          disableColumnSorting
          disableColumnResize
          density="standard"
          sx={{
            '.MuiDataGrid-footerContainer': { display: 'none' },
            '.MuiDataGrid-columnHeaders': {
              display: showHeader ? 'flex' : 'none',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
