import { useState } from 'react';
import {
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Stack,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import ConfiguraBene from './ConfiguraBene';
import BeniTable from './BeniTable';

const ConfiguraBeni = ({ beni, setBeni }) => {
  const theme = useTheme();
  const matchMd = useMediaQuery(theme.breakpoints.up('md'));

  const [isAddingNewBene, setIsAddingNewBene] = useState(false);
  const [isEditingBene, setIsEditingBene] = useState(false);
  const [beneToEdit, setBeneToEdit] = useState(null);

  const onAddNewBene = () => {
    setBeneToEdit(null);
    setIsAddingNewBene(true);
  };

  const onEditBene = bene => {
    setBeneToEdit(bene);
    setIsEditingBene(true);
  };

  const onCancelDetail = () => {
    if (isAddingNewBene) {
      setIsAddingNewBene(false);
    } else {
      setIsEditingBene(false);
    }
  };

  const onSaveDetail = bene => {
    let newBeni = [];
    if (isAddingNewBene) {
      //genero random id
      bene.id = Math.random().toString(36).substr(2, 9);
      newBeni = [...beni, bene];
      setIsAddingNewBene(false);
    } else {
      //modifico il bene esistente
      newBeni = beni.map(b => {
        if (b.id === bene.id) {
          return bene;
        }
        return b;
      });
      setIsEditingBene(false);
    }

    setBeni(newBeni);
  };

  const onQtaBeneChange = (id, qta) => {
    setBeni(
      beni.map(bene => {
        if (bene.id === id) {
          bene.quantita = qta;
        }
        return bene;
      })
    );
  };

  const onDeleteBene = id => {
    setBeni(beni.filter(bene => bene.id !== id));
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'end', mb: 2 }}>
        {!isAddingNewBene && !isEditingBene && (
          <Button
            startIcon={<Add />}
            variant="contained"
            size="small"
            onClick={onAddNewBene}
          >
            Aggiungi nuovo
          </Button>
        )}
      </Box>

      {(isAddingNewBene || isEditingBene) && (
        <Card
          sx={{ backgroundColor: 'background.default', position: 'relative' }}
        >
          {matchMd && (
            <Card
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: 30,
                height: 30,
                p: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'background.darkest',
              }}
            >
              {beneToEdit !== null ? (
                <Edit sx={{ color: 'white' }} />
              ) : (
                <Add sx={{ color: 'white' }} />
              )}
            </Card>
          )}
          <CardContent sx={{ p: matchMd ? 2 : 0 }}>
            <Typography variant="h6" component="h3" sx={{ mb: 2 }}>
              {isAddingNewBene ? 'Aggiungi Bene' : 'Modifica Bene'}
            </Typography>
            <ConfiguraBene
              bene={beneToEdit}
              onCancel={onCancelDetail}
              onSave={onSaveDetail}
            />
          </CardContent>
        </Card>
      )}

      <Box sx={{ mt: 4 }}>
        {beni.length > 0 ? (
          <BeniTable
            rows={beni}
            onQtaChange={onQtaBeneChange}
            onEdit={onEditBene}
            onDelete={onDeleteBene}
            readOnly={false}
          />
        ) : (
          <Stack sx={{ justifyContent: 'center' }} flexDirection="row">
            <Typography variant="body2" color="textSecondary">
              Nessun bene aggiunto
            </Typography>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default ConfiguraBeni;
