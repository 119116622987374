import Drawer, { drawerClasses } from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Card, IconButton } from '@mui/material';
import { ArrowForwardIos, NotificationsRounded } from '@mui/icons-material';
import { leggiNotifica } from '../redux/notifiche/api';
import { clearNotifica } from '../redux/notifiche/slice';
import { useNavigate } from 'react-router-dom';
import { toggleDrawer } from '../redux/app/slice';
import { useEffect, useRef, useState } from 'react';
import { ReactComponent as RichiestaModificaMenuSvg } from '../assets/img/RichiestaModificaMenu.svg';

const giorniSettimana = [
  'Domenica',
  'Lunedì',
  'Martedì',
  'Mercoledì',
  'Giovedì',
  'Venerdì',
  'Sabato',
];
const mesi = [
  'gennaio',
  'febbraio',
  'marzo',
  'aprile',
  'maggio',
  'giugno',
  'luglio',
  'agosto',
  'settembre',
  'ottobre',
  'novembre',
  'dicembre',
];

function NotificationDrawer({ headerRef }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const drawerRef = useRef(null);
  const { drawerOpen } = useSelector(state => state.app);
  const { notifiche } = useSelector(state => state.notifiche);
  const [headerHeight, setHeaderHeight] = useState(0);

  const formatDate = date => {
    const today = new Date();
    const d = new Date(date);
    let f = `${giorniSettimana[d.getDay()]} ${d.getDate()} ${mesi[d.getMonth()]} ${d.getFullYear()}`;
    if (
      d.getDate() === today.getDate() &&
      d.getMonth() === today.getMonth() &&
      d.getFullYear() === today.getFullYear()
    ) {
      f = `Oggi - ${f}`;
    }

    return f;
  };

  const onNotificaClick = notifica => {
    onLeggiNotifica(notifica.id);
    dispatch(toggleDrawer());
    switch (notifica.tipologia) {
      case 'MA':
      case 'MR':
        navigate('/richieste-modifica/edit', {
          state: { id: notifica.extra_dati.id },
        });
        break;
      case 'RQ':
      case 'RR':
        navigate('/noleggi/noleggio/edit', {
          state: { id: notifica.extra_dati.id },
        });
        break;
    }
  };

  //group by created_at day
  const groupedNotifiche = notifiche.reduce((acc, notifica) => {
    const date = formatDate(notifica.created_at);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(notifica);
    return acc;
  }, {});

  const onLeggiNotifica = async id => {
    try {
      dispatch(clearNotifica(id));
      await leggiNotifica({ id_notifica: id });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const _headerHeight = headerRef?.current?.clientHeight ?? 0;
    setHeaderHeight(_headerHeight);
  }, [headerRef]);

  useEffect(() => {
    document.addEventListener('mousedown', e => {
      if (drawerRef) {
        //se clicco outside drawer lo chiudo
        const drawerPos = drawerRef?.current?.getBoundingClientRect();
        if (!drawerPos) return;
        if (e.pageX < drawerPos.x || e.pageY < drawerPos.y) {
          dispatch(toggleDrawer());
        }
      }
    });

    return () => {
      document.removeEventListener('mousedown', () => {});
    };
  }, []);

  return (
    <Drawer
      variant={drawerOpen ? 'permanent' : 'temporary'}
      anchor="right"
      open={drawerOpen}
      onClose={() => {
        dispatch(toggleDrawer());
      }}
      sx={{
        flexShrink: 0,
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: 'secondary.main',
          minWidth: '200px',
          height: 'calc(100% - ' + headerHeight + 'px)',
          position: 'absolute',
          top: headerHeight,
        },
      }}
    >
      <Stack
        ref={drawerRef}
        sx={{
          width: '30dvw',
          height: '100%',
        }}
      >
        <Stack
          flexDirection="row"
          justifyContent={'space-between'}
          alignItems="center"
          sx={{ p: 2 }}
        >
          <Typography variant="h6" color="white">
            Notifiche
          </Typography>
        </Stack>

        {notifiche.length == 0 ? (
          <Stack sx={{ p: 2 }}>
            <Typography variant="body2" color="white">
              Nessuna notifica presente
            </Typography>
          </Stack>
        ) : (
          <></>
        )}

        {Object.keys(groupedNotifiche).map(date => (
          <Stack key={date} sx={{ p: 2, pt: 0 }}>
            <Typography variant="subtitle1" color="white">
              {date}
            </Typography>
            <Stack>
              {groupedNotifiche[date].map(notifica => (
                <Card key={notifica.id} sx={{ mb: 1 }}>
                  <Stack flexDirection="row" sx={{ alignItems: 'center' }}>
                    <Box
                      sx={{
                        p: 1,
                        borderRadius: '100%',
                        backgroundColor: 'primary.main',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {notifica.tipologia == 'MA' ||
                      notifica.tipologia == 'MR' ? (
                        <RichiestaModificaMenuSvg
                          style={{ color: 'white', width: 14, height: 14 }}
                        />
                      ) : (
                        <NotificationsRounded
                          sx={{ color: 'white', width: 20, height: 20 }}
                        />
                      )}
                    </Box>

                    <Box sx={{ flexGrow: 1, ml: 2 }}>
                      <Typography variant="body1">
                        {notifica.titolo.toUpperCase()}
                      </Typography>
                      <Typography variant="body2">
                        {notifica.descrizione}
                      </Typography>
                    </Box>
                    {(notifica.tipologia == 'RQ' ||
                      notifica.tipologia == 'RR' ||
                      notifica.tipologia == 'MA' ||
                      notifica.tipologia == 'MR') &&
                    notifica.extra_dati?.id ? (
                      <IconButton
                        sx={{ border: 'none' }}
                        size="small"
                        onClick={() => onNotificaClick(notifica)}
                      >
                        <ArrowForwardIos />
                      </IconButton>
                    ) : (
                      <></>
                    )}
                  </Stack>
                </Card>
              ))}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </Drawer>
  );
}

export default NotificationDrawer;
