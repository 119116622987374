import { z } from 'zod';
import useZod from '../../../hooks/useZod';

const formSchema = z.object({
  ragione_sociale: z
    .string()
    .min(1, { message: 'La ragione sociale è obbligatoria' }),
  cf: z.string().min(11, { message: 'Codice Fiscale non valido' }),
  piva: z.string().min(11, { message: 'Partita IVA non valida' }),
  mail: z.string().email({ message: 'Email non valida' }),
  telefono: z.string().min(10, { message: 'Numero di telefono non valido' }),
  locazione_indirizzo: z.string().min(1, { message: 'Indirizzo non valido' }),
  locazione_civico: z.string().min(1, { message: 'Numero civico non valido' }),
  locazione_cap: z.string().min(5, { message: 'CAP non valido' }),
  locazione_citta: z.string().min(1, { message: 'Città non valida' }),
  locazione_provincia: z.string().min(2, { message: 'Provincia non valida' }),
  sede_indirizzo: z.string().min(1, { message: 'Indirizzo non valido' }),
  sede_civico: z.string().min(1, { message: 'Numero civico non valido' }),
  sede_cap: z.string().min(5, { message: 'CAP non valido' }),
  sede_citta: z.string().min(1, { message: 'Città non valida' }),
  sede_provincia: z.string().min(2, { message: 'Provincia non valida' }),
  pec: z.string().email({ message: 'PEC non valida' }),
});

const useInserisciDatiCliente = azienda => {
  const {
    formData,
    setFormData,
    errors,
    handleInputChange,
    validate,
    resetErrors,
  } = useZod({
    schema: formSchema,
    initialFormData: azienda || {
      ragione_sociale: '',
      cf: '',
      piva: '',
      mail: '',
      telefono: '',
      locazione_indirizzo: '',
      locazione_civico: '',
      locazione_cap: '',
      locazione_citta: '',
      locazione_provincia: '',
      sede_indirizzo: '',
      sede_civico: '',
      sede_cap: '',
      sede_citta: '',
      sede_provincia: '',
      iban: '',
      pec: '',
    },
  });

  const handleCopyAddress = () => {
    setFormData(prevState => ({
      ...prevState,
      sede_indirizzo: prevState.locazione_indirizzo,
      sede_civico: prevState.locazione_civico,
      sede_cap: prevState.locazione_cap,
      sede_citta: prevState.locazione_citta,
      sede_provincia: prevState.locazione_provincia,
    }));
  };

  const handleSubmit = (requiresAll = false) => {
    if (!requiresAll) {
      //se tutti i valori sono empty non valido
      if (
        !Object.values(formData ?? {}).some(
          value => value !== '' && value !== false
        )
      ) {
        resetErrors();
        return 0;
      }
    }

    const validation = validate();
    if (validation.success) {
      return 1;
    }

    return -1;
  };
  return {
    setFormData,
    formData,
    errors,
    handleInputChange,
    handleCopyAddress,
    handleSubmit,
  };
};

export default useInserisciDatiCliente;
