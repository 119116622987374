import { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import { RestrictedRoute } from './RestrictedRoute';
import { PrivateRoute } from './PrivateRoute';
import { Box, CircularProgress } from '@mui/material';

import DashboardFornitore from '../pages/Dashboard/DashboardFornitore';
import DashboardCliente from '../pages/Dashboard/DashboardCliente';
import Login from '../pages/Login/Login';
import SetNewPassword from '../pages/User/SetNewPassword';
import Noleggi from '../pages/Noleggi/Noleggi';
import Noleggio from '../pages/Noleggi/Noleggio';
import GestioneAccount from '../pages/Account/GestioneAccount';
import Account from '../pages/Account/Account';
import Clienti from '../pages/Clienti/Clienti';
import Cliente from '../pages/Clienti/Cliente';
import DocumentiFatture from '../pages/DocumentiFatture/DocumentiFatture';
import Reports from '../pages/Report/Reports';
import Comunicazioni from '../pages/Comunicazioni/Comunicazioni';
import RichiesteModifica from '../pages/RichiesteModifiche/RichiesteModifiche';
import RichiestaModifica from '../pages/RichiesteModifiche/RichiestaModifica';
import Home from '../pages/Home/Home';

import Snackbar from '../components/Snackbar';
import AlertDialog from '../components/AlertDialog';
import Register from '../pages/Register/Register';
import useAuth from '../hooks/useAuth';

function LoadingSpinner() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <CircularProgress />
    </Box>
  );
}

const AppRoute = () => {
  const { user } = useAuth();

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Routes>
        <Route
          path="/login"
          element={
            <RestrictedRoute redirectTo="/dashboard" component={<Login />} />
          }
        />

        <Route
          path="/register"
          element={
            <RestrictedRoute redirectTo="/dashboard" component={<Register />} />
          }
        />

        <Route
          path="/"
          element={
            <RestrictedRoute redirectTo="/dashboard" component={<Home />} />
          }
        />

        <Route
          path="/reset-password"
          element={
            <PrivateRoute
              redirectTo="/login"
              component={<SetNewPassword />}
              isSetNewPasswordPage={true}
            />
          }
        />

        <Route
          path="/"
          element={<PrivateRoute redirectTo="/login" component={<Layout />} />}
        >
          {user?.flg_fornitore ? (
            <Route path="dashboard" element={<DashboardFornitore />} />
          ) : (
            <Route path="dashboard" element={<DashboardCliente />} />
          )}

          <Route path="/noleggi">
            <Route path="attivi" element={<Noleggi />} />
            <Route path="attivi/:type" element={<Noleggi />} />
            <Route path="non-attivi" element={<Noleggi />} />
            <Route path="non-attivi/:type" element={<Noleggi />} />
            <Route path="noleggio/new" element={<Noleggio />} />
            <Route path="noleggio/clone" element={<Noleggio />} />
            <Route path="noleggio/edit" element={<Noleggio />} />
            <Route path="noleggio/completa" element={<Noleggio />} />
          </Route>

          <Route path="/gestione-account">
            <Route index element={<GestioneAccount />} />
            <Route path="new" element={<Account />} />
            <Route path="edit" element={<Account />} />
          </Route>

          <Route path="/clienti">
            <Route index element={<Clienti />} />
            <Route path="edit" element={<Cliente />} />
          </Route>

          <Route path="/report">
            <Route index element={<Reports />} />
          </Route>

          <Route path="/comunicazioni">
            <Route index element={<Comunicazioni />} />
          </Route>

          <Route path="/documenti-fatture" element={<DocumentiFatture />} />

          <Route path="/richieste-modifica">
            <Route index element={<RichiesteModifica />} />
            <Route path="edit" element={<RichiestaModifica />} />
          </Route>
        </Route>

        <Route
          path="*"
          element={<PrivateRoute redirectTo="/" forceRedirect={true} />}
        />
      </Routes>
      <Snackbar />
      <AlertDialog />
    </Suspense>
  );
};

export default AppRoute;
