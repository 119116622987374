import { z } from 'zod';
import {
  TextField,
  Button,
  Grid2 as Grid,
  FormControl,
  Typography,
  Box,
  FormLabel,
  Stack,
  FormHelperText,
} from '@mui/material';
import SelectCategoriaProdotto from './SelectCategoriaProdotto';
import useZod from '../../../hooks/useZod';
import DecimalInputDecorated from '../../../components/DecimalInputDecorated';
import { Euro } from '@mui/icons-material';

const formSchema = z.object({
  descrizione: z.string().min(1, { message: 'La descrizione è obbligatoria' }),
  codice_prodotto: z.string().min(1, { message: 'Il codice è obbligatorio' }),
  produttore: z.string().min(1, { message: 'Il produttore è obbligatorio' }),
  id_categoria_prodotto: z
    .string()
    .min(1, { message: 'La categoria prodotto è obbligatoria' }),
  quantita: z.number().min(1, { message: 'La quantità è obbligatoria' }),
  importo: z.number().min(1, { message: "L'importo è obbligatorio" }),
});

const ConfiguraBene = ({ bene, onCancel, onSave }) => {
  const { errors, validate, setFormData, handleInputChange, formData } = useZod(
    {
      schema: formSchema,
      initialFormData: bene || {
        descrizione: '',
        codice_prodotto: '',
        produttore: '',
        id_categoria_prodotto: '',
        quantita: 0,
        importo: 0,
      },
    }
  );

  const handleSubmit = () => {
    const validation = validate();
    if (validation.success) {
      //todo: save data
      onSave(formData);
    }
  };

  const handleCategoriaChange = e => {
    setFormData(prevState => ({
      ...prevState,
      id_categoria_prodotto: e.target.value,
      categoria_prodotto_descrizione: e.target.descrizione,
    }));
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="categoriaProdotto">
              Categoria Prodotto
            </FormLabel>
            <SelectCategoriaProdotto
              placeholder="Categoria Prodotto"
              name="id_categoria_prodotto"
              value={formData.id_categoria_prodotto}
              onChange={handleCategoriaChange}
              errors={errors.id_categoria_prodotto}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="produttore">Produttore</FormLabel>

            <TextField
              placeholder="Produttore"
              name="produttore"
              variant="outlined"
              value={formData.produttore}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.produttore}
              helperText={errors.produttore}
            />
          </FormControl>
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="codice_prodotto">Codice Prodotto</FormLabel>
            <TextField
              placeholder="Codice Prodotto"
              name="codice_prodotto"
              variant="outlined"
              value={formData.codice_prodotto}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.codice_prodotto}
              helperText={errors.codice_prodotto}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid size={{ xs: 12, sm: 4 }}>
          <Grid size={{ xs: 12 }}>
            <FormControl>
              <FormLabel htmlFor="quantita">Quantità</FormLabel>
              <DecimalInputDecorated
                step={1}
                round={1}
                format={'decimal'}
                value={formData.quantita}
                minWidth="350px"
                onChange={value => {
                  handleInputChange({
                    target: {
                      name: 'quantita',
                      value: value,
                      type: 'number',
                    },
                  });
                }}
                name="quantita"
                style={{ justifyContent: 'start' }}
              />
            </FormControl>
            {errors.quantita && (
              <FormHelperText sx={{ color: 'error.main' }}>
                {errors.quantita}
              </FormHelperText>
            )}
          </Grid>
          <Grid size={{ xs: 12 }} sx={{ mt: 2 }}>
            <FormControl>
              <FormLabel htmlFor="importo">
                Importo
                <Typography variant="caption">
                  <br />
                  Inserisci l'importo senza IVA
                </Typography>
              </FormLabel>
              <DecimalInputDecorated
                step={1}
                round={0.01}
                format={'EUR'}
                decorationButtons={true}
                LeftButtonIcon={null}
                RightButtonIcon={Euro}
                value={formData.importo}
                minWidth="400px"
                onChange={value => {
                  handleInputChange({
                    target: {
                      name: 'importo',
                      value: value,
                      type: 'number',
                    },
                  });
                }}
                name="importo"
                style={{ justifyContent: 'start' }}
              />
              {errors.importo && (
                <FormHelperText sx={{ color: 'error.main' }}>
                  {errors.importo}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, sm: 8 }}>
          <FormControl fullWidth>
            <FormLabel htmlFor="descrizione">Descrizione</FormLabel>
            <TextField
              multiline
              rows={6}
              placeholder="Descrizione"
              name="descrizione"
              variant="outlined"
              value={formData.descrizione}
              onChange={handleInputChange}
              fullWidth
              error={!!errors.descrizione}
              helperText={errors.descrizione}
            />
          </FormControl>
        </Grid>
      </Grid>

      <Stack flexDirection="row" justifyContent="end" gap={2} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="info"
          onClick={onCancel}
          size="small"
        >
          Annulla
        </Button>
        <Button variant="contained" onClick={handleSubmit} size="small">
          Salva e {bene ? 'Modifica' : 'Aggiungi'} Bene
        </Button>
      </Stack>
    </Box>
  );
};

export default ConfiguraBene;
