import {
  Box,
  Card,
  Grid2 as Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import useAuth from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { Search } from '@mui/icons-material';
import NoleggiTable from '../Noleggi/components/NoleggiTable';
import useNoleggiTable from '../Noleggi/hook/useNoleggiTable';
import IntestazioneNoleggioVerticale from '../Noleggi/components/IntestazioneNoleggioVerticale';
import DocumentiGrid from '../Noleggi/components/DocumentiGrid';
import { useDispatch, useSelector } from 'react-redux';
import { getDocumenti } from '../../redux/noleggi/operations';

export default function Dashboard() {
  const { user } = useAuth();
  const [noleggio, setNoleggio] = useState({
    durata_mesi: 24,
    importo: 0,
    flg_assicurazione: false,
  });
  const dispatch = useDispatch();

  const { documenti } = useSelector(state => state.noleggi);
  useEffect(() => {
    dispatch(getDocumenti());
  }, []);
  const { attivi, filter, getNoleggiAction } = useNoleggiTable([
    'confermati',
    'recenti',
    'attivi',
  ]);

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      <Grid container spacing={1}>
        <Grid size={{ xs: 12, md: 7 }}>
          <Box>
            <Card sx={{ backgroundColor: 'background.default' }}>
              <Typography component="h2" variant="h4">
                Benvenuto {user?.ragione_sociale},
              </Typography>
              <Stack
                flexDirection="row"
                justifyContent="space-between"
                sx={{ mb: 1, mt: 4 }}
              >
                <Box>
                  <Stack flexDirection="row">
                    <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                      Visualizza i&nbsp;
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{ fontFamily: 'Poppins-SemiBold' }}
                        color="primary"
                      >
                        Noleggi Attivi
                      </Typography>
                    </Typography>
                  </Stack>
                  <Typography variant="caption">
                    I noleggi che hai attualmente in corso, seleziona per
                    visualizzarne i dettagli.
                  </Typography>
                </Box>
              </Stack>
              <TextField
                onChange={e => filter(e.target.value, 'attivi')}
                sx={{ mb: 1 }}
                placeholder="Cerca"
                size="small"
                variant="outlined"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search sx={{ width: 20, height: 20 }} />
                      </InputAdornment>
                    ),
                  },
                }}
              />

              <NoleggiTable
                noleggi={attivi?.noleggiAttivi ?? []}
                isLoading={getNoleggiAction?.loading ?? false}
                initialState={{
                  pagination: { paginationModel: { pageSize: 5 } },
                }}
                pageSizeOptions={[5, 10, 20, 50]}
                showSearch={false}
              />
            </Card>

            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Card
                  sx={{
                    backgroundColor: 'background.default',
                    height: '100%',
                    flexGrow: 1,
                  }}
                >
                  <Stack flexDirection="row">
                    <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                      Visualizza i&nbsp;
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{ fontFamily: 'Poppins-SemiBold' }}
                        color="primary"
                      >
                        Noleggi Attivi
                      </Typography>
                    </Typography>
                  </Stack>
                </Card>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Card
                  sx={{
                    backgroundColor: 'background.default',
                    height: '100%',
                    flexGrow: 1,
                  }}
                >
                  <Stack flexDirection="row">
                    <Typography sx={{ fontFamily: 'Poppins-SemiBold' }}>
                      Visualizza &nbsp;
                      <Typography
                        component="span"
                        variant="body1"
                        sx={{ fontFamily: 'Poppins-SemiBold' }}
                        color="primary"
                      >
                        Documenti
                      </Typography>
                    </Typography>
                  </Stack>
                  <DocumentiGrid data={documenti} />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid size={{ xs: 12, md: 5 }}>
          <Card
            sx={{
              backgroundColor: 'background.default',
              height: '100%',
              p: 0.5,
            }}
          >
            <IntestazioneNoleggioVerticale
              noleggio={noleggio}
              setNoleggio={setNoleggio}
              showButtons
            />
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
