import Grid from '@mui/material/Grid2';
import { DataGrid } from '@mui/x-data-grid';
import { IconButton, Stack } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import { formatCurrency } from '../../../utils/formatNumber';
import { formatStringDate } from '../../../utils/formadDate';

export default function RichiesteModificaTable({
  rows,
  onOpenRichiesta,
  action,
}) {
  const columns = [
    {
      field: 'data',
      headerName: 'Data Richiesta Modifica',
      flex: 1,
      valueFormatter: value => {
        return formatStringDate(value);
      },
    },
    { field: 'codice', headerName: 'Codice Operazione', flex: 1 },
    { field: 'cliente', headerName: 'Cliente', flex: 1 },
    {
      field: 'importo',
      headerName: 'Importo',
      flex: 1,
      valueFormatter: value => formatCurrency(value),
    },
    {
      field: 'importo_aggiornato',
      headerName: 'Importo Aggiornato',
      flex: 1,
      valueFormatter: value => formatCurrency(value),
    },
    {
      field: 'nome_stato',
      headerName: 'Stato',
      flex: 1,
    },
    {
      field: 'utente',
      headerName: 'Utente',
      flex: 1,
    },
    {
      field: 'azioni',
      headerName: '',
      sortable: false,
      headerClassName: 'no-separator',
      cellClassName: 'no-separator',
      disableColumnMenu: true,
      renderCell: params => (
        <Stack
          flexDirection="row"
          gap={1}
          sx={{
            justifyContent: 'center',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <IconButton
            size="small"
            sx={{
              border: 'none',
              backgroundColor: 'transparent',
            }}
            onClick={() => onOpenRichiesta(params.row)}
          >
            <ArrowForwardIos />
          </IconButton>
        </Stack>
      ),
    },
  ];

  return (
    <Grid container spacing={2} columns={12}>
      <Grid size={{ md: 12, lg: 12 }} sx={{ width: '100%' }}>
        <DataGrid
          onRowDoubleClick={params => onOpenRichiesta(params.row)}
          autoHeight
          rows={rows}
          columns={columns}
          loading={action.loading}
          getRowClassName={params =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          initialState={{
            pagination: { paginationModel: { pageSize: 20 } },
          }}
          pageSizeOptions={[10, 20, 50]}
          disableColumnResize
          density="standard"
        />
      </Grid>
    </Grid>
  );
}
